export const HOMEPAGE_LINK_ALT_TEXT = 'Capital One Dining homepage';
export const CONTINUE_BUTTON_TEXT = 'Continue';
export const NEXT_BUTTON_TEXT = 'Next';
export const CLOSE_BUTTON_LABEL = 'close';
export const DISMISS_BUTTON_LABEL = 'dismiss';
export const INFORMATION_BANNER = 'information banner';
export const BACK_BUTTON_LABEL = 'back';
export const CLOSE_BUTTON_ALT_TEXT = 'close';
export const CLOSE_BUTTON_DESCRIPTION = 'Go back to previous screen.';
export const SEARCH_BUTTON_TEXT = 'Find a restaurant';
export const SEE_MORE_RESTAURANTS_BUTTON_TEXT = 'Discover more restaurants';
export const EDIT_BUTTON_TEXT = 'Edit';
export const EDIT_BUTTON_ALT_TEXT_PREFIX = 'Edit ';
export const RESUME_SESSION_BUTTON_TEXT = 'Wait, I’m still here!';
export const SECONDS_TEXT = 'seconds';
export const DIALOG_TITLE = 'Anyone out there?';
export const INACTIVITY_TEXT =
  'After a few minutes of inactivity we will sign you out to keep your account safe.';
export const HOUR_GLASS_ICON_ALT_TEXT = 'Hour Glass icon';

export const CANCELED_LABEL = 'Canceled';
export const CALENDAR_INPUT_LABEL = 'Date (MM/DD/YYYY)';
export const SPECIAL_EVENT_CALENDAR_INPUT_LABEL = 'Date';

// leave spaces in date format for screenreaders to announce properly
export const CALENDAR_INPUT_SCREENREADER_LABEL = 'Date (MM/DD/Y Y Y Y)';
export const CALENDAR_DIALOG_SCREENREADER_TEXT =
  'Calendar Expanded. Press the tab key to enter the calendar dialog';
export const VIEW_CALENDAR_DIALOG = 'View calendar';
export const TIME_INPUT_LABEL = 'Time';
export const GUESTS_INPUT_LABEL = 'Guests';
export const FAB_SUBMIT_LABEL = 'Check availability';
export const APPLY_BUTTON_TEXT = 'Apply';
export const DATE_NONE_SELECTED = 'Not selected';
export const RIGHT_ARROW_ALT_TEXT = 'Right arrow';
export const DOWN_ARROW_ALT_TEXT = 'Down arrow';
export const RESIZE_TEXT_BOX_ALT_TEXT = 'minimum height';
export const ICON_BACK_ARROW_ALT_TEXT = 'Back button arrow';
export const ICON_RIGHT_ARROW_ALT_TEXT = 'right';
export const ICON_MAP_PIN_ALT_TEXT = 'map pin';
export const ICON_EMPTY_LIST = 'Icon for empty reservation list';
export const ICON_GENERIC_ERROR_ALT_TEXT = 'Oops!';
export const ICON_CALENDAR_ALT_TEXT = 'Calendar';
export const ICON_CAUTION_ALT_TEXT = 'Caution';
export const PROGRESS_BAR_LABEL = 'Loading';
// common/onboardingLoading
export const COMMON_ONBOARDING_LOADING_TITLE =
  'You’re moments away from booking your exclusive Capital One Dining reservation.';
export const COMMON_ONBOARDING_LOADING_SUBTITLE =
  'Loading award-winning restaurants...';
export const EXPLORE_RESTAURANTS_BUTTON_TEXT = 'Explore restaurants';
export const SKIP_AND_EXPLORE_RESTAURANTS_BUTTON_TEXT =
  'Skip & explore restaurants';
// onboarding/index
export const ONBOARDING_EDIT_PROFILE_BUTTON_TEXT = 'Personalize profile';
// onboarding/location
export const ONBOARDING_LOCATION_TITLE = 'Find a table near you.';
export const ONBOARDING_LOCATION_INPUT_LABEL = 'Select a city';
export const ONBOARDING_LOCATION_INPUT_PLACEHOLDER = 'Select one';
export const ONBOARDING_LOCATION_TOOLTIP_LABEL = 'Don’t see your city?';
export const ONBOARDING_LOCATION_TOOLTIP_TEXT =
  'We’re launching our collection of award-winning restaurants in select cities across the country, and looking to expand to new locations.';
// onboarding/welcome
export const ONBOARDING_WELCOME_TITLE_PREFIX = 'Welcome, ';
export const ONBOARDING_WELCOME_TITLE_SUFFIX = '!';
export const ONBOARDING_WELCOME_SUBTITLE =
  'Enjoy your exclusive dining benefits';
export const ONBOARDING_BULLET_ONE_TEXT =
  'Book hard-to-get restaurant reservations, set aside just for cardholders.';
export const ONBOARDING_BULLET_TWO_TEXT =
  'Explore an exceptional list of top-rated restaurants, curated in partnership with culinary experts.';
export const ONBOARDING_BULLET_THREE_TEXT =
  'Reserve your table and personalize your dining preferences online in just a few taps.';
// onboarding/ConfirmInfo
export const ONBOARDING_CONFIRM_INFO_TITLE =
  'We’ve pulled in your information.';
export const ONBOARDING_CONFIRM_INFO_SUBTITLE =
  'Please enter your phone number.';
export const ONBOARDING_CONFIRM_INFO_INPUT_LABEL = 'Phone number';
export const ONBOARDING_CONFIRM_INFO_INPUT_PLACEHOLDER = 'XXX-XXX-XXXX';
export const ONBOARDING_CONFIRM_INFO_INPUT_GENERAL_ERROR =
  'Phone number is required.';
export const INVALID_PHONE_INPUT_ERROR = 'This is not a valid phone number';
export const ONBOARDING_CONFIRM_INFO_DESCRIPTION =
  'If the information you see here isn’t correct, please update it in your account profile through the Capital One app or website.';
// onboarding/personalize
export const ONBOARDING_PERSONALIZE_TITLE =
  'Personalize your dining profile for incredible meals and memories ahead.';
export const ONBOARDING_SPECIAL_DATES_TITLE = 'Share your special dates';
export const ONBOARDING_SPECIAL_DATES_USER_BIRTHDAY_LABEL =
  'Your birthday (MM/DD)';
export const ONBOARDING_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL =
  'Partner’s birthday (MM/DD)';
export const ONBOARDING_SPECIAL_DATES_USER_ANNIVERSARY_LABEL =
  'Your anniversary (MM/DD)';
export const ONBOARDING_CONFIRM_INFORMATION =
  'All fields are optional. This information will be shared with our partners when you book a reservation.';
export const ONBOARDING_DIETARY_PREFERENCES_TITLE =
  'Select your dietary preferences';
//home
export const HOME_SELECT_CITY = 'Select city ';
export const HOME_VIEW_ALL_BUTTON_TEXT_PREFIX = 'View all restaurants in ';
export const HOME_EXPLORE_ALL_BUTTON_TEXT_PREFIX =
  'Explore all restaurants in ';
export const HOME_HERO_TITLE = 'Discover unforgettable dining.';
export const HOME_HERO_DESCRIPTION_TEXT =
  'As a Capital One cardholder, you have access to exclusive reservations and experiences from noteworthy chefs and restaurants.';
export const HOME_HERO_ACTION_BUTTON_TEXT = 'See all restaurants';
export const HOME_ACCOLADES_SUBTITLE = 'Explore accolades';
export const HOME_CUISINES_SUBTITLE = 'Explore cuisines';
export const HOME_NEIGHBORHOODS_SUBTITLE = 'Explore neighborhoods';
export const HOME_ENTERTAINMENT_BANNER_TEXT =
  'Get cardholder-only access to unforgettable events in dining, sports, music and more through Capital One Entertainment.';
export const HOME_RESTAURANT_TIMESLOT_TEXT =
  '{{formattedSuggestedDate}} at {{defaultTime}}';
export const HOME_RESTAURANT_TIMESLOT_ALT_TEXT =
  'Find a reservation on {{formattedSuggestedDate}} at {{defaultTime}}';
export const HOME_RESERVATION_GUESTS_ALT_TEXT =
  'Find a reservation for {{guestsInflection}}';
export const HOME_FIND_RESTAURANT_TEXT = 'Find restaurants';

export const HOME_HERO_CAROUSEL_TITLE = 'Dining Highlights';

//home hero event
export const HOME_HERO_EVENT_TITLE =
  'Exclusive <i>Atelier minibar</i> event with <b>Chef José Andrés & Chef Dominique Crenn</b>';
export const HOME_HERO_EVENT_DESCRIPTION_TEXT =
  'For 2-nights only, enjoy a special dining experience in Washington, D.C.';
export const EVENT_CAROUSEL_TITLE = 'Featured dining experiences';
export const EVENT_CAROUSEL_DESCRIPTION =
  'Upcoming special events with our partners like the MICHELIN Guide, the James Beard Foundation and more.';
export const ENTERTAINMENT_BANNER_BUTTON_TEXT = 'Explore experiences';
// home/Link
export const HOME_LINK = 'Back to home';

// error/DefaultError
export const CONTACT_US_ERROR_MESSAGE =
  'To see if you can access Capital One Dining, you’ll need to give us a call.';
export const CONTACT_US_ERROR_BUTTON_TEXT = 'Contact us';
export const CONTACT_US_ERROR_BUTTON_URL =
  'https://www.capitalone.com/support-center/contact-us';
export const INELIGIBLE_ERROR_MESSAGE =
  'To access Capital One Dining you need an eligible credit card associated with this account.';
export const INELIGIBLE_ERROR_BUTTON_TEXT = 'Discover eligible credit cards';
export const INELIGIBLE_ERROR_BUTTON_URL =
  'https://www.capitalone.com/credit-cards/lp/rewards/lp1/';
export const INVALID_EMAIL_ERROR_MESSAGE =
  'To access Capital One Dining, please update the email address in your account Profile in the Capital One mobile app or website.';

export const INVALID_PHONE_NUMBER_ERROR_MESSAGE =
  "To access Capital One Dining, please update the mobile phone number in your account Profile in the Capital One mobile app or on the website and ensure you've opted into receiving automated calls and texts.";

export const UNAUTHORIZED_ERROR_MESSAGE =
  'Your session has expired. Please sign in again.';

//premierLocation/AllPremier
export const PREMIER_LOCATION_ALL_SELECTIONS = 'All selections';
export const PREMIER_LOCATION_LOAD_MORE = 'Show more restaurants';
//premierLocation/TopHeroe
export const PREMIER_LOCATION_TITLE = 'Premier dining selections';

//profile/index
export const PROFILE_TITLE = 'My dining profile';
export const PROFILE_SCREENREADER_SUBTITLE = 'Name and email address';
export const PROFILE_MARKET_LABEL = 'Preferred city';
export const PROFILE_PHONE_LABEL = 'Phone number';
export const PROFILE_SPECIAL_DATES_SECTION_LABEL = 'Special dates';
export const PROFILE_SPECIAL_DATES_USER_BIRTHDAY_LABEL = 'Your birthday';
export const PROFILE_SPECIAL_DATES_PARTNER_BIRTHDAY_LABEL =
  'Partner’s birthday';
export const PROFILE_SPECIAL_DATES_USER_ANNIVERSARY_LABEL = 'Your anniversary';
export const PROFILE_DIETARY_PREFERENCES_SECTION_LABEL = 'Dietary preferences';
export const PROFILE_DIETARY_PREFERENCES_DIETS_LABEL = 'Restrictions';
export const PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL = 'Allergies';
export const PROFILE_DIETARY_PREFERENCES_NOTES_LABEL = 'Additional notes';
export const PROFILE_CONFIRM_SPECIAL_DATES =
  'All fields are optional. This information will be shared with our partners when you book a reservation.';
export const PAYMENT_METHODS_TITLE = 'Payment methods';
export const PAYMENT_METHODS_CREDIT_CARD = 'Ending in';
export const PROFILE_PAYMENT_METHOD_INFO =
  'We’ve added your Capital One card so you no longer need to manually enter payment details at booking.';
//profile edit
export const EDIT_PROFILIE_CITY_TITLE = 'Edit preferred city';
export const EDIT_PROFILE_PHONE_TITLE = 'Edit phone number';
export const DEFAULT_TEXT = 'Default';
export const DEFAULT_PAYMENT_TEXT = 'Default payment';
export const DEFAULT_PAYMENT_ACTION_TEXT =
  'Use this card as the default payment method for future reservations.';
export const EDIT_PROFILE_PHONE_SUBTITLE =
  'This number will be used as the default for all of your reservations.';
export const EDIT_PROFILE_SPECIAL_DATES_TITLE = 'Edit special dates';
export const EDIT_PROFILE_SPECIAL_DATES_GENERAL_ERROR =
  'Please enter a valid date.';
export const EDIT_PROFILE_DIETARY_PREFERENCES_TITLE =
  'Edit dietary preferences';
export const EDIT_PROFILE_DIETARY_PREFERENCES_SUBTITLE =
  'This information will be used as the default for all of your reservations.';
export const PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED =
  'No restrictions';
export const PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED =
  'No allergies';
export const PROFILE_DIETARY_PREFERENCES_NOTES_NONE_SELECTED =
  'No additional notes';
export const EDIT_DEFAULT_PAYMENT_TITLE = 'Edit default payment method';
export const EDIT_DEFAULT_PAYMENT_BUTTON_LABEL = 'Edit default payment';
export const EDIT_PAYMENT_BUTTON_TITLE = 'Edit payment method';

// reservations/index
export const RESERVATIONS_TITLE = 'My reservations';
export const RESERVATIONS_UPCOMING_TAB_LABEL = 'Upcoming';
export const RESERVATIONS_PAST_TAB_LABEL = 'Past';
export const RESERVATIONS_DETAILS_LABEL = 'Reservation details';
export const RESERVATIONS_SEE_DETAILS = 'See details';
export const RESERVATIONS_SEE_RESTAURANT = 'See restaurant';
export const RESERVATIONS_SEE_EVENT = 'See event';
export const RESERVATIONS_NO_UPCOMING_TITLE = 'No upcoming reservations';
export const RESERVATIONS_NO_UPCOMING_SUBTITLE =
  'Once you book your reservation, you’ll be able to view and update it here.';
export const RESERVATIONS_NO_PAST_TITLE = 'No past reservations';
export const RESERVATIONS_NO_PAST_SUBTITLE =
  'You don’t have any past reservations yet—but when you do, you’ll find them here.';

//reserve/contact
export const CONTACT_NUMBER_EMPTY_ERROR = 'This is a required field';
export const CONTACT_INFO_TITLE = 'Contact Info';
export const CONTACT_INFO_NO_PHONE_NUMBER =
  'Please enter your phone number to complete this reservation. To automatically pull in your phone number in the future, please update the phone number in your account Profile in the Capital One mobile app or on the website.';
export const PHONE_NUMBER_LABEL = 'Phone Number';
export const PHONE_NUMBER_PLACEHOLDER = '(XXX) XXX-XXXX';

//reserve/index
export const PAYMENT_POLICY_LABEL = 'Payment policy';
export const BOOKING_POLICY_LABEL = 'Booking policy';
export const RESERVE_EDIT_SPECIAL_REQUESTS = 'Additional notes (optional)';
export const RESERVE_EDIT_SHARING_NOTICE =
  'Changes to these details will only be used for this reservation and will be shared with our partners. All fields are optional.';
export const PROFILE_CONTACT_DETAILS_SUBTITLE = 'Contact details';
export const PROFILE_CONTACT_DETAILS_INFORMATION =
  'This information has been pulled in from your Capital One profile. If it is incorrect, please update it in your account profile through the Capital One app or website.';
export const NOTES_PLACEHOLDER = 'Enter your note here...';
export const SPECIAL_OCCASION_LABEL = 'Special occasion (optional)';
export const SPECIAL_REQUESTS_SECTION_LABEL = 'Special requests';
export const SPECIAL_REQUESTS_LABEL = 'Special requests (optional)';
export const SPECIAL_REQUESTS_OUT_OF = ' out of ';
export const SPECIAL_REQUESTS_CHARACTERS_USED = ' characters used';
export const RESERVE_EDIT_CONTACT_DETAILS = 'Edit contact details';
export const RESERVE_EDIT_CONTACT_DETAILS_NOTICE =
  'This information will apply only for this reservation and will be shared with our partners.';
export const RESERVE_EDIT_SPECIAL_OCCASIONS_UPDATE_LABEL =
  'Update special occasion';
export const RESERVE_PRESELECTED_SPECIAL_OCCASION_TEXT =
  'A special occasion from your dining profile has been pre-selected.';
export const SELECT_BUTTON_LABEL = 'Select';
export const RESERVE_NO_AVAILABILITY =
  'Looks like that time is no longer available.';
export const RESERVE_VIEW_MORE_AVAILABILITY = 'View more availability';
export const UPDATE_PHONE_NUMBER_INFO =
  "We've updated your phone number to match your Capital One profile.";
export const RESERVE_STRIPE_STEP_UP_ABORTED =
  'Please manually enter your payment information.';
export const RESERVE_STRIPE_STEP_UP_OR_PAYMENT_FAILED =
  'An error has occurred. Please manually enter your payment information.';
export const CREDIT_CARD_VERIFICATION_CODE_LABEL = 'CVC';

//reserve/availability
export const RESERVATION_EDIT_TITLE = 'Edit your reservation';
export const RESERVATION_EDIT_CURRENT_LABEL = 'Current reservation';
export const RESERVATION_EDIT_NEW_RESERVATION_LABEL = 'Select new reservation';
export const RESERVATION_DATE_SELECT = 'date select';
export const RESERVATION_GUESTS_SELECT = 'select number of guests';
//reserve/confirm
export const RESERVATION_CONFIRMED_SUCCESS = 'Reservation confirmed';
export const RESERVATION_CONFIRMED_FAILED = 'Reservation failed';
export const RESERVATION_CONFIRMED_NAVIGATE_TO_RESERVATIONS =
  'Go to my reservations';
export const RESERVATION_ENTERTAINMENT_BANNER_TEXT =
  'Make it dinner and a show. Enjoy cardholder-only access to thousands of events through Capital One Entertainment.';
export const RESERVATION_SHEET_PRICE_UNIT = '/person';
export const RESERVATION_PAYMENT_EDIT_TEXT = 'Done';
export const RESERVATION_PAYMENT_EDIT_HELPER_TEXT = 'Done and go back';
export const RESERVE_PAYMENT_METHOD_INFO =
  'We’ve added your Capital One card so you no longer need to manually enter payment details.';
export const RESERVE_MULTI_CARDS_PAYMENT_METHOD_INFO =
  'We’ve added your Capital One cards so you no longer need to manually enter payment details. ';
export const RESERVE_MULTI_CARDS_ACTION_MESSAGE = 'Select your default card';

//reservationDetails
export const PAST_RESERVATION_TITLE = 'Your past reservation';
export const UPCOMING_RESERVATION_TITLE = 'Upcoming reservation';
export const RESERVATION_DETAILS_SCREENREADER_TEXT =
  'Reservation and venue details';
export const RESERVATION_TITLE = 'Reservation';
export const RESERVATION_TITLE_ALT_TEXT = 'Reservation';
export const DINING_OPTION_TITLE = 'Dining option';
export const PAYMENT_SECTION_LABEL = 'Reservation charges';
export const PAYMENT_VIEW_RECIEPT_BUTTON_TEXT = 'View receipt';
export const PAYMENT_CUSTOM_TIP_LABEL = 'Custom';
export const PAYMENT_TIP_LABEL = 'Tip';
export const PAYMENT_TIP_LABEL_ALT_TEXT = 'Tip charge';
export const PAYMENT_TAX_LABEL = 'Tax';
export const PAYMENT_TAX_LABEL_ALT_TEXT = 'Tax charge';
export const PAYMENT_CUSTOM_TIP_AMOUNT_LABEL = 'Tip Amount';
export const PAYMENT_CUSTOM_TIP_SAVE_BUTTON_TEXT = 'Save tip';
export const PAYMENT_TOTAL_LABEL_ALT_TEXT = 'Total paid';
export const PAYMENT_TOTAL_LABEL = 'Total paid';
export const PAYMENT_INFORMATION_SECTION_TITLE = 'Payment information';
export const PAYMENT_NAME_ON_CARD_LABEL = 'Name on card';
export const PAYMENT_CARD_NUMBER_LABEL = 'Card number';
export const EDIT_RESERVATION_WARNING_TEXT = `To modify this reservation, please contact the restaurant
directly. You may also cancel this reservation and rebook.
Please note that this reservation is subject to any applicable
payment policy, including any cancellation fees for this
restaurant.`;
export const EDIT_RESERVATION_NO_PAYMENT_ALLOWED =
  'Editing a reservation to a time where payment is required is not supported. Please cancel your current reservation and rebook.';
export const CANCEL_RESERVATION_BUTTON_TEXT = 'Cancel reservation';
export const EDIT_EXISTING_RESERVATION_WARNING =
  'To make changes and updates on this reservation, please contact the restaurant directly.';
export const EDIT_EXISTING_RESERVATION_FIND_TIMESLOT = 'Find a table';
export const UPDATE_EXISTING_RESERVATION_BUTTON_TEXT =
  'Update your reservation';
export const RESERVATION_FAILED_ERROR_ALREADY_EXISTS_TITLE =
  'Your reservation already exists.';
export const RESERVATION_FAILED_ERROR_ALREADY_EXISTS_MESSAGE =
  'Please cancel your existing reservation or book a different date or time.';
export const RESERVATION_FAILED_ERROR_ALREADY_EXISTS_BUTTON_TEXT =
  'Go to my reservations';
export const RESERVATION_LIST_EMPTY_ALT_TEXT =
  'Icon for empty reservation list';
export const VENUE_TYPE_EVENT = 'Event';
export const VENUE_TYPE_REGULAR = 'Dining';
export const RESERVATION_CANCEL_ERROR_TITLE = 'Oops, we’ve hit a snag!';
export const RESERVATION_CANCEL_ERROR_MESSAGE =
  'Reservation cannot be modified after the cancellation cut-off time';
export const RESERVATION_CANCEL_ERROR_BUTTON_TEXT = 'Back to my reservations';
export const RESERVATION_UPDATE_ERROR_TITLE = 'Oops, we’ve hit a snag!';
export const RESERVATION_UPDATE_ERROR_MESSAGE =
  'Reservation cannot be modified after the cancellation cut-off time';
export const RESERVATION_UPDATE_ERROR_BUTTON_TEXT = 'Back to my reservations';
export const RESERVATION_CANCEL_CONFIRMED_TITLE = 'Cancellation confirmed';
export const RESERVATION_UPDATE_CONFIRMED_TITLE = 'Reservation updated';
export const RESERVATION_RECEIPT_TITLE = 'Reservation charges';
export const RESERVATION_RECEIPT_TITLE_ALT_TEXT = 'Reservation charge';
export const RESERVATION_RECEIPT_RESERVATION_FEE_LABEL = 'Reservation';
export const RESERVATION_RECEIPT_SERVICE_FEE_LABEL = 'Service charge';
export const RESERVATION_RECEIPT_PREFIX_TIP_LABEL = 'Prefixed tip';
export const RESERVATION_RECEIPT_TOTAL_LABEL = 'Order total';
export const RESERVATION_SERVICE_CHARGE_TOOLTIP =
  'Administrative fee charged by the restaurant in addition to the reservation price. For more questions about this charge, please contact the restaurant directly.';
export const RESERVATION_REQUIRES_PAYMENT_NOTICE =
  'This restaurant requires a credit card on file in order to place a reservation.';
export const RESERVATION_ID_LABEL = 'Reservation ID:';
//search
export const SEARCH_ACTION_BUTTON_TEXT = 'Search';
export const SEARCH_TITLE = 'Search';
export const SEARCH_NAME = 'search';
export const SEARCH_INPUT_PLACEHOLDER = 'Find a restaurant or cuisine';
export const SEARCH_CLEAR_ALT_TEXT = 'clear text';
export const SEARCH_INPUT_LABEL = 'Restaurant or cuisine';
export const SEARCH_FILTER_SECTION_ALT_TEXT = 'Select filters to apply';
export const SEARCH_FILTERS_ALT_TEXT = 'Filters';
export const SEARCH_FILTER_LABEL = 'Filter';
export const SEARCH_FILTER_COUNT_PREFIX = 'Filter (';
export const SEARCH_FILTER_COUNT_SUFFIX = ')';
export const SEARCH_FILTER_INFLECTION = 'Filter';
export const SEARCH_FILTER_INFLECTION_SUFFIX = ' selected.';
export const SEARCH_FILTER_REMOVE_PREFIX = 'Remove ';
export const SEARCH_FILTER_REMOVE_SUFFIX = ' filter';
export const SEARCH_FILTER_PRICE_RANGE_LABEL = 'Price range';
export const SEARCH_FILTER_CATEGORY_LABEL = 'Category';
export const SEARCH_FILTER_ACCOLADE_LABEL = 'Accolade';
export const SEARCH_FILTER_CUISINE_LABEL = 'Cuisine';
export const SEARCH_FILTER_ATMOSPHERE_LABEL = 'Atmosphere';
export const SEARCH_FILTER_NEIGHBORHOOD_LABEL = 'Neighborhood';
export const SEARCH_FILTER_CLEAR_LABEL = 'Clear filters';
export const SEARCH_FILTER_RESERVATION_TYPE_LABEL = 'Reservation type';
export const SEARCH_RESULT_INFLECTION = 'result';
export const SEARCH_RESULT_NO_RESULT_PREFIX = 'No results for "';
export const SEARCH_RESULT_NO_RESULT_JOIN = '" in ';
export const SEARCH_RESULT_NO_RESULT_JOIN_2 = '.  ';
export const SEARCH_RESULT_NO_RESULT_SUFFIX = ' in other cities.';
export const SEARCH_RESULT_SEE_ALL_PREFIX = 'See all restaurants in ';
export const SEARCH_RESULT_OTHER_LABEL = 'Results in other cities';
export const SEARCH_LOAD_MORE = 'Show more';
export const VENUE_EVENT_DATE_LINK_TEXT = 'View Event';
export const VENUE_EVENT_DETAILS_LINK_TEXT = 'View event details';
export const SEARCH_PAGINATION_NAV_LABEL = 'Venues list pagination';
export const SEARCH_PAGINATION_HEADING = 'Pagination';
export const SEARCH_PAGINATION_CURRENT_PAGE = 'Current page {{pageNumber}}';
export const SEARCH_PAGINATION_PAGE = 'Page {{pageNumber}}, display results';
export const SEARCH_PAGINATION_ARROW = '{{direction}} page';
export const SEARCH_PAGINATION_DOTS = '...';
export const SEARCH_RESULTS_COUNT =
  '{{firstItem}}–{{lastItem}} of {{totalCount}} results';
export const SEARCH_RESULTS_SCREEN_READER_COUNT =
  'Showing {{firstItem}} to {{lastItem}} of {{totalCount}} results.';

//venue
export const AVAILABILITY_NONE_TEXT = 'No available tables around this time';
export const VENUE_CONTACT_INFO_TITLE = 'Contact info';
export const VENUE_CONTACT_INFO_LAUNCH_WEBSITE = 'Visit website';
export const VENUE_SEATING_OPTIONS_TITLE = 'Seating options';
export const VENUE_ATMOSPHERE_TITLE = 'Atmosphere';
export const VENUE_PHOTOS_ALT_TEXT_PREFIX = 'Showing photo ';
export const VENUE_IMAGE_ALT_TEXT_PREFIX = 'Restaurant Image ';
export const VENUE_DETAILS_JOIN = ' • ';
export const COMMA_JOIN = ', ';
export const VENUE_LINKS_TITLE = 'Links';
export const VENUE_AVAILABILITY_TITLE = 'Select a reservation';
export const VENUE_DETAILS_ALT_TEXT = 'Restaurant details';
export const AVAILABLE_TIMES_LABEL = 'Available times';
export const AVAILABLE_TIMES_ALT_TEXT = 'Available times';
export const VENUE_MINIMUM_PRICE_TEXT = '';
export const VENUE_MAXIMUM_PRICE_TEXT = 'Very High Price';
export const VENUE_SEE_TEXT = 'see';
export const VENUE_IMAGE_FROM_TEXT = 'image from';
export const VENUE_DETAILS_TEXT = 'details';
export const ACCLAIMED_DINING = 'Acclaimed Dining';
export const VENUE_SEE_ALL_PHOTOS = 'See all {{imageCount}} photos';
export const GET_DIRECTIONS_LINK_TEXT = 'Get directions';
export const VIEW_LARGER_MAP_TEXT = 'View larger map';
export const ADA_GET_DIRECTIONS_TO_RESTAURANT_LINK_TEXT =
  'Get directions to {{restaurantName}}';

//components/reserve
export const RESERVATION_CREATE_TITLE = 'Complete your reservation';
export const RESERVATION_UPCOMING_TITLE = 'Upcoming reservation';
export const RESERVATION_EDIT_CONFIRM_TITLE = 'Confirm updated reservation';
export const RESERVATION_EDIT_CONFIRM_SCREENREADER = 'Confirm reservation';
export const RESERVATION_EDIT_CANCEL_SCREENREADER =
  'Edit or cancel reservation';
export const RESERVATION_UPDATE_SCREENREADER = 'Update reservation';
export const RESERVATION_TERMS_RESERVE_LINE_ONE =
  'By clicking "Reserve now," you agree to our ';
export const RESERVATION_TERMS_RESERVE_LINE_TWO = ' and ';
export const RESERVATION_TERMS_TERMS_OF_SERVICE_LABEL = 'terms of service';
export const RESERVATION_TERMS_PRIVACY_POLICY_LABEL = 'privacy policy';
export const TERMS_OF_SERVICE_LABEL = 'Terms of service';
export const TERMS_OF_SERVICE_LINK_URL =
  'https://www.capitalone.com/credit-cards/disclosures/dining-terms-of-service';
export const PRIVACY_POLICY_LABEL = 'Privacy policy';
export const PRIVACY_POLICY_LINK_URL =
  'https://www.capitalone.com/privacy/online-privacy-policy';
export const COPYRIGHT_LABEL = '© 2024 Capital One';
export const RESERVATION_COMPLETE_CONFIRM_NOTICE =
  'The information you provided above will be shared with our partners to complete your reservation. Accountholders and members of their party should contact the venue or property, as applicable, with any questions on accessibility or accommodations. Capital One makes no representations and does not guarantee any requested accommodation will be provided.';
export const SENTENCE_END = '. ';
export const CANCELLATION_POLICY_LABEL = 'Cancellation policy';
export const CANCELLATION_POLICY_TEXT =
  'We reserve the right to charge a cancellation fee for any booking that is canceled, or if numbers are reduced without prior notice';
export const RESERVATION_TERMS_UPDATE_LINE_ONE =
  'By clicking "Update Reservation" you agree to our ';
export const RESERVATION_TERMS_UPDATE_LINE_TWO = ' and ';
export const CREATE_RESERVATION_BUTTON_TEXT = 'Reserve now';
export const EDIT_RESERVATION_BUTTON_TEXT = 'Edit reservation';
export const UPDATE_RESERVATION_BUTTON_TEXT = 'Update reservation';
export const RESERVATION_EDIT_CANCEL_MODAL_TITLE = 'Are you sure?';
export const RESERVATION_EDIT_CANCEL_MODAL_DESCRIPTION =
  "The changes you've made to this reservation will not be saved if you exit.";
export const RESERVATION_EDIT_CANCEL_MODAL_NO_BUTTON_TEXT = 'No';
export const RESERVATION_EDIT_CANCEL_MODAL_YES_BUTTON_TEXT = 'Yes';
export const RESERVATION_CANCEL_MODAL_TITLE =
  'Are you sure you want to cancel?';
export const RESERVATION_CANCEL_MODAL_DESCRIPTION =
  'This reservation is subject to any applicable payment policy, including any cancellation fees for this restaurant.';
export const RESERVATION_CANCEL_MODAL_NO_BUTTON_TEXT = 'No, thanks';
export const RESERVATION_CANCEL_MODAL_YES_BUTTON_TEXT = 'Yes, cancel';
export const RESERVATION_CANCEL_MODAL_YES_EXIT_BUTTON_TEXT = 'Yes, exit';

export const RESERVATION_CHANGES_AND_UPDATE_CONTACT_RESTAURANT_TEXT =
  'To make changes and updates on this reservation, please contact the restaurant directly.';

//components/search
export const SEARCH_AVAILABILITY_NONE =
  'Unfortunately, there’s nothing available that matches your request. Please try a different party size, date or time.';
export const AVAILABILITY_GENERIC_NONE = 'No availability';
export const AVAILABILITY_MORE_BUTTON_TEXT = 'Show other available dates';
export const AVAILABILITY_MORE_TEXT = 'Other dates';
export const AVAILABILITY_OTHERS_TOOLTIP =
  'All availabilities may not be shown. Select desired date to see all availability within that day.';
export const AVAILABILITY_ALL_BUTTON_TEXT = 'View next availability';
export const VENUE_RESULT_SEE_VENUE_PREFIX = 'See more information for ';
export const SEARCH_SUGGESTED_RESULTS_LABEL = 'Suggested results';
export const CUISINES_LABEL = 'Cuisine';
export const RESTAURANTS_LABEL = 'Restaurant';
export const RECENT_SEARCHES_LABEL = 'Recent';
//components/onboarding
export const STEP_FOOTER_PREFIX_TEXT = 'Step ';
//components/home
export const HOME_VIEW_ALL_ALT_TEXT_PREFIX = 'View all ';
export const HOME_VIEW_ALL_BUTTON_TEXT = 'View all';
//components/core
export const NAVIGATION_VIEW_RESERVATIONS = 'My reservations';
export const NAVIGATION_VIEW_PROFILE = 'My dining profile';
export const NAVIGATION_VIEW_SIGN_OUT = 'Sign out';
export const NAVIGATION_RESTAURANTS = 'Explore restaurants';
export const NAVIGATION_PREMIER_LOCATIONS = 'Premier dining';
export const EXPANDED_TEXT = 'Expanded';
export const COLLAPSED_TEXT = 'Collapsed';
export const WELCOME_TEXT = 'Welcome, {{firstName}}';
export const NAVIGATION_DIALOG_SCREENREADER_TEXT =
  'Menu popup expanded. Press the tab key to enter the menu dialog';
export const NAVIGATION_DINING_EXPERIENCE = 'Experiences';
export const VIEW_NEXT_TEXT = 'View next';
export const VIEW_PREVIOUS_TEXT = 'View previous';
export const VIEW_FULL_SIZE_TEXT = 'View full size';
export const EVENTS_TEXT = 'events';
export const RESTAURANTS_TEXT = 'restaurants';
export const TIMESLOT_LABEL = 'time slots';
export const TEXT_JOIN_OF = ' of ';
export const MOBILE_NAVIGATION_MARKET_PREFIX = 'City: ';
export const MOBILE_NAVIGATION_VIEW_ALL = 'View all restaurants';
export const MOBILE_NAVIGATION_VIEW_ALL_ALT_TEXT = 'view-all-restaurants-arrow';
export const GENERIC_ERROR_NO_BUTTON_TITLE = 'Oops, we’ve hit a snag!';
export const GENERIC_ERROR_NO_BUTTON_MESSAGE =
  'Looks like we need to fix something, so we’re working on it. Try again in a bit.';
export const GENERIC_ERROR_TITLE = 'We’ve hit a snag.';
export const GENERIC_ERROR_MESSAGE = 'Looks like we need to fix something.';
export const PAGE_NOT_FOUND_TITLE = 'We can’t find that page.';
export const PAGE_NOT_FOUND_MESSAGE =
  'Don’t worry, we’ll get you back on track.';
export const LOGIN_ERROR_TITLE = 'We’ve hit a snag.';
export const LOGIN_ERROR_MESSAGE =
  'Looks like we need to fix something. Try logging in again.';
export const LOGIN_ERROR_BUTTON_TEXT = 'Back to log in';
export const SAVE_BUTTON_TEXT = 'Save';
export const SAVE_CONTINUE_BUTTON_TEXT = 'Save & continue';
export const SAVE_BUTTON_HELPER_TEXT =
  'Save input and go back to previous screen.';
export const NAVIGATION_HIDE_ALT_TEXT = 'Hide navigation menu';
export const NAVIGATION_SHOW_ALT_TEXT = 'Show navigation menu';
export const MENU_ALT_TEXT = 'Menu';
export const SEARCH_RESTAURANTS_ALT_TEXT = 'Search restaurants';
//components/core/cards
export const GUEST_INFLECTION = 'guest';
export const RESERVATION_IMAGE_ALT_TEXT = 'Reservation image';
export const CARD_DIRECTIONS_LABEL = 'Directions';
export const CARD_WEBSITE_LABEL = 'Website';
export const CARD_MENU_LABEL = 'Menu';
export const CARD_VIEW_RESTERVATION_ALT_TEXT = 'View reservation';
export const CARD_SEE_STATUS = 'See an overview of this status.';
//pages/error
export const MAINTENANCE_ERROR_MESSAGE =
  "Pardon the interruption! We're working on improvements to Capital One Dining. Please check back soon.";
export const ERROR_SIGNOUT_BUTTON_TEXT = 'Sign out';
//Read More Less
export const READ_MORE = 'Read more';
export const SHOW_LESS = 'Show less';
export const READ_MORE_SHOW_LESS_SCREEN_READER_TEXT =
  'Display {{amount}} venue description';
export const FULL_VENUE_DESCTIPTION_TEXT = 'Full Venue Description:';
// Availability
export const AVAILABILITY_NO_TABLES_ON_SELECTED_DATE =
  'Unfortunately, there are no tables available for {{guestsInflection}} on {{selectedDate}}. Please update your party size or date.';
export const AVAILABILITY_NO_TABLES_ON_SELECTED_DATE_OR_NEXT_7_DAYS =
  'Unfortunately, there are no tables available for {{guestsInflection}} on {{selectedDate}} or the 7 days following.<br><br>Please update your party size or date.';
export const AVAILABILITY_NO_TABLES_WITHIN_NEXT_7_DAYS =
  'Unfortunately, there are no tables available for {{guestsInflection}} within the next 7 days.';
export const AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS =
  'Unfortunately, there are no tickets available for {{guestsInflection}} on {{selectedDate}}. Please update your party size or date.';
export const GO_TO_EVENT_LINK_TEXT = 'View event on {{date}}';
export const INACTIVE_VENUE_TEXT =
  'Unfortunately, this restaurant is no longer participating in the Capital One Dining program.';
export const NO_TIMESLOT_FOR_NUMBER_OF_TICKET =
  'No availability matching selected number of tickets.';
export const EVENT_DROPDOWN_BUTTON_TEXT = 'Get tickets';
export const EVENT_DROPDOWN_HEADER = 'Quantity';
export const EVENT_DROPDOWN_LABEL = 'tickets';

//Special Events
export const EVENT_CTA_TEXT = 'Get tickets';
export const AVAILABILITY_NO_TICKETS_SPECIAL_EVENTS_WITHOUT_DATES =
  'No availability matching selected number of tickets.';
export const SALES_CLOSED_TEXT =
  'Unfortunately, there are no tickets available at this time. Please check back later.';
export const COMING_SOON_TEXT =
  'Tickets for {{venueName}} will be on sale soon! Please check back later.';
export const PAST_EVENT_TEXT =
  '{{venueName}} has already occurred and tickets are no longer available.';
export const SOLD_OUT_TEXT =
  'Unfortunately, tickets for {{venueName}} are no longer available. Please check back later.';
export const SOLD_OUT_BADGE_TEXT = 'Sold out';
export const EVENT_TEXT = 'event';

export const DINING_EXPERIENCE_TITLE = 'Featured dining experiences';
export const DINING_EXPERIENCE_SUBTITLE =
  'Enjoy unforgettable culinary experiences–from once-in-a-lifetime chef collaborations to arts and dining mash-ups and more. Explore special cardholder-only events and ticket access, only on Capital One Dining.';
export const DINING_EXPERIENCE_VIDEO_TITLE =
  'Look back at highlights from previous Capital One Dining experiences';
export const DINING_EXPERIENCE_VIDEO_TRANSCRIPT =
  'Video transcription: CapitalOne Dining logo appears, (upbeat music playing) Enjoy exclusive access to culinary experiences like José Andrés at The Met. Access top restaurants curated by culinary experts. Book hard-to-get reservations set aside just for cardholders. CapitalOne Dining logo appears, Explore unique culinary events, exclusive access to award-winning restaurants and more. End of video transcription.';

export const CARDHOLDER_EXCLUSIVE_TEXT = 'Capital One Exclusive';
export const EVENT_STATUS_COMING_SOON_TEXT = 'UPCOMING';
export const EVENT_STATUS_ON_SALE_TEXT = 'ON SALE';
export const EVENT_STATUS_PAST_TEXT = 'PAST';
export const EVENT_STATUS_SOLD_OUT_TEXT = 'SOLD OUT';
export const MULTIPLE_DATE_TEXT = 'Multiple dates';
export const ONLY_TEXT = 'Only';
export const NO_EVENTS_TITLE = 'No upcoming events';
export const NO_EVENTS_TEXT =
  'Unfortunately, there are no upcoming events at this time. We’re always working to add more, so make sure to check back at a later date!';

//Exclusive Tagging
export const EXCLUSIVE_ALERT_TITLE =
  'Look for restaurants with the Signature Collection icon to access Cardholder and Insider Tables.';
export const EXCLUSIVE_TIMESLOT_DESCRIPTION =
  'The accented time slots indicate tables held exclusively for Capital One cardholders.';
export const CARDHOLDER_TIMESLOT_TITLE = 'Cardholder Tables';
export const CARDHOLDER_DESCRIPTION = 'Tables set aside just for cardholders.';
export const INSIDER_TIMESLOT_TITLE = 'Insider Tables';
export const INSIDER_DESCRIPTION =
  'Cardholder Tables that designate you as an insider, a status that comes with special perks from the restaurant when available.';
export const LEARN_MORE_BUTTON_TEXT = 'Learn more';
export const CARDHOLDER_TABLE_TEXT = 'Cardholder Table';
export const INSIDER_TABLE_TEXT = 'Insider Table';
export const SIGNATURE_COLLECTION_TEXT = 'Signature Collection';
export const ABOUT_THE_TEXT = 'about the';

// Core/Select
export const GUEST_SELECTOR_DROPDOWN_LABEL = 'Number of guests';

export const LOADING_TEXT = 'loading';

export const BOOK_A_TABLE_TEXT = 'Book a table';

// Calendar Availability
export const CALENDAR_NO_AVAILABILITY_TEXT = 'No availability in {{month}}.';
export const CALENDAR_SEARCH_AVAILABILITY_TEXT =
  'Search availability for {{month}}';
