/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAvailability = /* GraphQL */ `
  query GetAvailability(
    $sevenRoomsVenueId: String!
    $date: AWSDate
    $startTime: String
    $endTime: String
    $partySize: Int
    $experienceId: String
  ) {
    getAvailability(
      sevenRoomsVenueId: $sevenRoomsVenueId
      date: $date
      startTime: $startTime
      endTime: $endTime
      partySize: $partySize
      experienceId: $experienceId
    ) {
      id
      name
      date
      timeslots {
        requiresCreditCard
        timeSlot
        timeDisplay
        isHeld
        shiftCategory
        payment {
          chargeType
          applyGratuityCharge
          gratuityType
          ccPartySizeMin
          mandatedGratuityAmt
          applyServiceCharge
          requireGratuityCharge
          gratuity
          serviceChargeAmt
          taxAmt
          cost
          taxRate
          defaultGratuity
          pregratuityTotal
          subtotal
          ccPaymentRule
          serviceCharge
          requireCreditCard
          paymentPolicy
        }
        slotName
        slotTitle
        slotBodyPlaintext
        realDateTime
        slotBodyHtml
        slotOptionId
        bookingPolicy
        isExclusive
        experienceId
      }
      paymentInformation {
        paymentSystem
        sevenroomsPublicKey
        accountId
      }
    }
  }
`;
export const findAvailability = /* GraphQL */ `
  query FindAvailability(
    $sevenRoomsVenueId: String!
    $date: AWSDate
    $time: String
    $partySize: Int
    $experienceId: String
  ) {
    findAvailability(
      sevenRoomsVenueId: $sevenRoomsVenueId
      date: $date
      time: $time
      partySize: $partySize
      experienceId: $experienceId
    ) {
      id
      name
      date
      timeslots {
        requiresCreditCard
        timeSlot
        timeDisplay
        isHeld
        shiftCategory
        payment {
          chargeType
          applyGratuityCharge
          gratuityType
          ccPartySizeMin
          mandatedGratuityAmt
          applyServiceCharge
          requireGratuityCharge
          gratuity
          serviceChargeAmt
          taxAmt
          cost
          taxRate
          defaultGratuity
          pregratuityTotal
          subtotal
          ccPaymentRule
          serviceCharge
          requireCreditCard
          paymentPolicy
        }
        slotName
        slotTitle
        slotBodyPlaintext
        realDateTime
        slotBodyHtml
        slotOptionId
        bookingPolicy
        isExclusive
        experienceId
      }
      paymentInformation {
        paymentSystem
        sevenroomsPublicKey
        accountId
      }
    }
  }
`;
export const listAvailability = /* GraphQL */ `
  query ListAvailability(
    $sevenRoomsVenueId: String!
    $startDate: AWSDate
    $endDate: AWSDate
    $startTime: String
    $endTime: String
    $partySize: Int
    $experienceId: String
  ) {
    listAvailability(
      sevenRoomsVenueId: $sevenRoomsVenueId
      startDate: $startDate
      endDate: $endDate
      startTime: $startTime
      endTime: $endTime
      partySize: $partySize
      experienceId: $experienceId
    ) {
      id
      name
      date
      timeslots {
        requiresCreditCard
        timeSlot
        timeDisplay
        isHeld
        shiftCategory
        payment {
          chargeType
          applyGratuityCharge
          gratuityType
          ccPartySizeMin
          mandatedGratuityAmt
          applyServiceCharge
          requireGratuityCharge
          gratuity
          serviceChargeAmt
          taxAmt
          cost
          taxRate
          defaultGratuity
          pregratuityTotal
          subtotal
          ccPaymentRule
          serviceCharge
          requireCreditCard
          paymentPolicy
        }
        slotName
        slotTitle
        slotBodyPlaintext
        realDateTime
        slotBodyHtml
        slotOptionId
        bookingPolicy
        isExclusive
        experienceId
      }
      paymentInformation {
        paymentSystem
        sevenroomsPublicKey
        accountId
      }
    }
  }
`;
export const listAvailabilityVenues = /* GraphQL */ `
  query ListAvailabilityVenues($input: ListAvailabilityVenuesInput!) {
    listAvailabilityVenues(input: $input) {
      sevenRoomsVenueId
      experienceId
      availability {
        id
        name
        date
        timeslots {
          requiresCreditCard
          timeSlot
          timeDisplay
          isHeld
          shiftCategory
          payment {
            chargeType
            applyGratuityCharge
            gratuityType
            ccPartySizeMin
            mandatedGratuityAmt
            applyServiceCharge
            requireGratuityCharge
            gratuity
            serviceChargeAmt
            taxAmt
            cost
            taxRate
            defaultGratuity
            pregratuityTotal
            subtotal
            ccPaymentRule
            serviceCharge
            requireCreditCard
            paymentPolicy
          }
          slotName
          slotTitle
          slotBodyPlaintext
          realDateTime
          slotBodyHtml
          slotOptionId
          bookingPolicy
          isExclusive
          experienceId
        }
        paymentInformation {
          paymentSystem
          sevenroomsPublicKey
          accountId
        }
      }
    }
  }
`;

export const getDateRangeAvailability =  /* GraphQL */`
  query GetDateRangeAvailability(
    $sevenRoomsVenueId: String!
    $date: AWSDate
    $startTime: String
    $endTime: String
    $partySize: Int
    $daysOut: Int
  ) {
    getDateRangeAvailability(
      sevenRoomsVenueId: $sevenRoomsVenueId,
      date: $date,
      startTime: $startTime,
      endTime: $endTime,
      partySize: $partySize,
      daysOut: $daysOut
    ) {
      id
      name
      date
      timeslots {
        requiresCreditCard
        timeSlot
        timeDisplay
        payment {
          gratuityType
          mandatedGratuityAmt
          gratuity
          serviceChargeAmt
          taxAmt
          cost
          pregratuityTotal
          subtotal
          ccPaymentRule
          serviceCharge
          paymentPolicy
        }
        slotName
        realDateTime
        slotBodyHtml
        slotOptionId
        bookingPolicy
        isExclusive
        experienceId
      }
      paymentInformation {
        sevenroomsPublicKey
        accountId
      }
    }
  }
`;
export const getReservation7R = /* GraphQL */ `
  query GetReservation7R($id: ID!) {
    getReservation7R(id: $id) {
      id
      sevenRoomsReservationId
      userId
      venueId
      venue {
        id
        name
        address
        city
        state
        zipcode
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        timezoneName
        images
        websiteUrl
        menuUrl
        sevenRoomsVenueId
        phoneNumber
        bookingPolicy
        paymentPolicy
        specialEventDate
        venueType
        isInsider
      }
      sevenRoomsVenueId
      slotOptionId
      slotName
      date
      time
      realDateTime
      firstName
      lastName
      phone
      guests
      email
      status
      isActive
      sevenRoomsStatus
      statusCode
      sevenRoomsCreated
      sevenRoomsUpdated
      sevenRoomsDeleted
      costOption
      referenceCode
      diets
      allergies
      specialOccasions
      notes
      bookingPolicy
      paymentPolicy
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
    }
  }
`;
export const listReservationsByUser7R = /* GraphQL */ `
  query ListReservationsByUser7R($userId: ID!) {
    listReservationsByUser7R(userId: $userId) {
      id
      sevenRoomsReservationId
      userId
      venueId
      venue {
        id
        name
        address
        city
        state
        zipcode
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        timezoneName
        images
        websiteUrl
        menuUrl
        sevenRoomsVenueId
        phoneNumber
        bookingPolicy
        paymentPolicy
        specialEventDate
        venueType
        isInsider
      }
      sevenRoomsVenueId
      slotOptionId
      slotName
      date
      time
      realDateTime
      firstName
      lastName
      phone
      guests
      email
      status
      isActive
      sevenRoomsStatus
      statusCode
      sevenRoomsCreated
      sevenRoomsUpdated
      sevenRoomsDeleted
      costOption
      referenceCode
      diets
      allergies
      specialOccasions
      notes
      bookingPolicy
      paymentPolicy
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
    }
  }
`;
export const searchVenuesQuery = /* GraphQL */ `
  query SearchVenuesQuery(
    $input: VenueSearchInput
    $filter: SearchableVenueFilterInput
  ) {
    searchVenuesQuery(input: $input, filter: $filter) {
      error
      success
      items {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const getAccolade = /* GraphQL */ `
  query GetAccolade($id: ID!) {
    getAccolade(id: $id) {
      id
      name
      airtableId
      icon
      sortOrder
      awards {
        items {
          id
          accoladeId
          awardId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          accoladeId
          tagId
          accolade {
            id
            name
            airtableId
            icon
            sortOrder
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            isActive
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listAccolades = /* GraphQL */ `
  query ListAccolades(
    $filter: ModelAccoladeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccolades(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccoladeAward = /* GraphQL */ `
  query GetAccoladeAward($id: ID!) {
    getAccoladeAward(id: $id) {
      id
      accoladeId
      awardId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccoladeAwards = /* GraphQL */ `
  query ListAccoladeAwards(
    $filter: ModelAccoladeAwardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccoladeAwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accoladeId
        awardId
        accolade {
          id
          name
          airtableId
          icon
          sortOrder
          awards {
            items {
              id
              accoladeId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              accoladeId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          isActive
          createdAt
          updatedAt
        }
        award {
          id
          name
          airtableId
          primaryText
          secondaryText
          icon
          status
          isActive
          venues {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAccoladeTag = /* GraphQL */ `
  query GetAccoladeTag($id: ID!) {
    getAccoladeTag(id: $id) {
      id
      accoladeId
      tagId
      accolade {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAccoladeTags = /* GraphQL */ `
  query ListAccoladeTags(
    $filter: ModelAccoladeTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccoladeTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accoladeId
        tagId
        accolade {
          id
          name
          airtableId
          icon
          sortOrder
          awards {
            items {
              id
              accoladeId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              accoladeId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          isActive
          createdAt
          updatedAt
        }
        tag {
          id
          name
          airtableId
          isActive
          venues {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAllergy = /* GraphQL */ `
  query GetAllergy($id: ID!) {
    getAllergy(id: $id) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listAllergys = /* GraphQL */ `
  query ListAllergys(
    $filter: ModelAllergyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAllergys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAtmosphere = /* GraphQL */ `
  query GetAtmosphere($id: ID!) {
    getAtmosphere(id: $id) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAtmospheres = /* GraphQL */ `
  query ListAtmospheres(
    $filter: ModelAtmosphereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAtmospheres(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAward = /* GraphQL */ `
  query GetAward($id: ID!) {
    getAward(id: $id) {
      id
      name
      airtableId
      primaryText
      secondaryText
      icon
      status
      isActive
      venues {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAwards = /* GraphQL */ `
  query ListAwards(
    $filter: ModelAwardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      airtableId
      description
      sortOrder
      status
      isActive
      isHighlighted
      venues {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCuisine = /* GraphQL */ `
  query GetCuisine($id: ID!) {
    getCuisine(id: $id) {
      id
      name
      airtableId
      images
      isActive
      venues {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCuisines = /* GraphQL */ `
  query ListCuisines(
    $filter: ModelCuisineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCuisines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiet = /* GraphQL */ `
  query GetDiet($id: ID!) {
    getDiet(id: $id) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listDiets = /* GraphQL */ `
  query ListDiets(
    $filter: ModelDietFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGeocode = /* GraphQL */ `
  query GetGeocode($id: ID!) {
    getGeocode(id: $id) {
      id
      latitude
      longitude
      expirationTimestamp
      createdAt
      updatedAt
    }
  }
`;
export const listGeocodes = /* GraphQL */ `
  query ListGeocodes(
    $filter: ModelGeocodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeocodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        latitude
        longitude
        expirationTimestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMarket = /* GraphQL */ `
  query GetMarket($id: ID!) {
    getMarket(id: $id) {
      id
      name
      airtableId
      image
      isActive
      venues {
        items {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      neighborhoods {
        items {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMarkets = /* GraphQL */ `
  query ListMarkets(
    $filter: ModelMarketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMarkets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNeighborhood = /* GraphQL */ `
  query GetNeighborhood($id: ID!) {
    getNeighborhood(id: $id) {
      id
      name
      airtableId
      isActive
      marketId
      createdAt
      updatedAt
    }
  }
`;
export const listNeighborhoods = /* GraphQL */ `
  query ListNeighborhoods(
    $filter: ModelNeighborhoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNeighborhoods(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPartner = /* GraphQL */ `
  query GetPartner($id: ID!) {
    getPartner(id: $id) {
      id
      name
      airtableId
      isActive
      icon
      venues {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPartners = /* GraphQL */ `
  query ListPartners(
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPremierLocation = /* GraphQL */ `
  query GetPremierLocation($id: ID!) {
    getPremierLocation(id: $id) {
      id
      name
      subtitle
      airtableId
      sortOrder
      chefName
      images
      description
      status
      spotlight
      isActive
      venues {
        items {
          id
          venueId
          premierLocationId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          premierLocation {
            id
            name
            subtitle
            airtableId
            sortOrder
            chefName
            images
            description
            status
            spotlight
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPremierLocations = /* GraphQL */ `
  query ListPremierLocations(
    $filter: ModelPremierLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremierLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPremierLocationVenue = /* GraphQL */ `
  query GetPremierLocationVenue($id: ID!) {
    getPremierLocationVenue(id: $id) {
      id
      venueId
      premierLocationId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      premierLocation {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPremierLocationVenues = /* GraphQL */ `
  query ListPremierLocationVenues(
    $filter: ModelPremierLocationVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPremierLocationVenues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueId
        premierLocationId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        premierLocation {
          id
          name
          subtitle
          airtableId
          sortOrder
          chefName
          images
          description
          status
          spotlight
          isActive
          venues {
            items {
              id
              venueId
              premierLocationId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservation = /* GraphQL */ `
  query GetReservation($id: ID!) {
    getReservation(id: $id) {
      id
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationId
      slotOptionId
      slotName
      bookingPolicy
      paymentPolicy
      status
      sevenRoomsStatusCode
      date
      time
      guests
      paymentAccountReferenceId
      paymentGratuity
      paymentServiceCharge
      paymentTax
      paymentSubTotal
      paymentRule
      isActive
      isExclusive
      createdAt
      updatedAt
    }
  }
`;
export const listReservations = /* GraphQL */ `
  query ListReservations(
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationId
        slotOptionId
        slotName
        bookingPolicy
        paymentPolicy
        status
        sevenRoomsStatusCode
        date
        time
        guests
        paymentAccountReferenceId
        paymentGratuity
        paymentServiceCharge
        paymentTax
        paymentSubTotal
        paymentRule
        isActive
        isExclusive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReservationRequest = /* GraphQL */ `
  query GetReservationRequest($id: ID!) {
    getReservationRequest(id: $id) {
      id
      date
      time
      guests
      realDateTime
      userId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      venueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      sevenRoomsReservationRequestId
      isActive
      referenceCode
      isCustomerCancel
      c1Status
      createdAt
      updatedAt
    }
  }
`;
export const listReservationRequests = /* GraphQL */ `
  query ListReservationRequests(
    $filter: ModelReservationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReservationRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        guests
        realDateTime
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationRequestId
        isActive
        referenceCode
        isCustomerCancel
        c1Status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSeatingOption = /* GraphQL */ `
  query GetSeatingOption($id: ID!) {
    getSeatingOption(id: $id) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSeatingOptions = /* GraphQL */ `
  query ListSeatingOptions(
    $filter: ModelSeatingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeatingOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialOccasion = /* GraphQL */ `
  query GetSpecialOccasion($id: ID!) {
    getSpecialOccasion(id: $id) {
      id
      name
      airtableId
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialOccasions = /* GraphQL */ `
  query ListSpecialOccasions(
    $filter: ModelSpecialOccasionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialOccasions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      airtableId
      isActive
      venues {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      c1CustRefId
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      userBirthday
      partnerBirthday
      userAnniversary
      notes
      defaultTipPercent
      allergies {
        items {
          id
          userId
          allergyId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          allergy {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      diets {
        items {
          id
          userId
          dietId
          user {
            id
            c1CustRefId
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            userBirthday
            partnerBirthday
            userAnniversary
            notes
            defaultTipPercent
            allergies {
              nextToken
            }
            diets {
              nextToken
            }
            hidePhoneNumberAlert
            isTest
            hidePaymentMethodAlert
            defaultPaymentMethodAccountId
            hideExclusiveTaggingAlert
            createdAt
            updatedAt
          }
          diet {
            id
            name
            airtableId
            isActive
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      hidePhoneNumberAlert
      isTest
      hidePaymentMethodAlert
      defaultPaymentMethodAccountId
      hideExclusiveTaggingAlert
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAllergy = /* GraphQL */ `
  query GetUserAllergy($id: ID!) {
    getUserAllergy(id: $id) {
      id
      userId
      allergyId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      allergy {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserAllergys = /* GraphQL */ `
  query ListUserAllergys(
    $filter: ModelUserAllergyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAllergys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        allergyId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        allergy {
          id
          name
          airtableId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserDiet = /* GraphQL */ `
  query GetUserDiet($id: ID!) {
    getUserDiet(id: $id) {
      id
      userId
      dietId
      user {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      diet {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserDiets = /* GraphQL */ `
  query ListUserDiets(
    $filter: ModelUserDietFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDiets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        dietId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        diet {
          id
          name
          airtableId
          isActive
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      name
      airtableId
      sevenRoomsVenueId
      sevenRoomsMunicipalityId
      description
      websiteUrl
      menuUrl
      startOfDay
      dineDash
      bookingPolicy
      paymentPolicy
      phoneNumber
      city
      state
      zipcode
      address
      googlePlaceId
      neighborhoodId
      neighborhood {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      normalizedName
      marketId
      market {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      geocodeId
      geocode {
        id
        latitude
        longitude
        expirationTimestamp
        createdAt
        updatedAt
      }
      categories {
        items {
          id
          venueId
          categoryId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          category {
            id
            name
            airtableId
            description
            sortOrder
            status
            isActive
            isHighlighted
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      atmospheres {
        items {
          id
          venueId
          atmosphereId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          atmosphere {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisines {
        items {
          id
          venueId
          cuisineId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          cuisine {
            id
            name
            airtableId
            images
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      cuisineNames
      awards {
        items {
          id
          venueId
          awardId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          award {
            id
            name
            airtableId
            primaryText
            secondaryText
            icon
            status
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          venueId
          tagId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          tag {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      seatingOptions {
        items {
          id
          venueId
          seatingOptionId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          seatingOption {
            id
            name
            airtableId
            isActive
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      priceRange
      imagesSmall
      images
      imagesFull
      timezoneName
      sortOrder
      isActive
      specialEventDate
      eventSalesClosed
      eventSoldOut
      badgeText
      isTest
      isSignatureCollection
      isInsider
      salesStartDate
      salesEndDate
      eventDates
      isSoldOut
      isCardholderExclusive
      venueType
      experienceId
      isHidden
      relatedVenues {
        items {
          id
          venueId
          relatedVenueId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          relatedVenue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      partners {
        items {
          id
          venueId
          partnerId
          venue {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          partner {
            id
            name
            airtableId
            isActive
            icon
            venues {
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueRelated = /* GraphQL */ `
  query GetVenueRelated($id: ID!) {
    getVenueRelated(id: $id) {
      id
      venueId
      relatedVenueId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      relatedVenue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueRelateds = /* GraphQL */ `
  query ListVenueRelateds(
    $filter: ModelVenueRelatedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueRelateds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        relatedVenueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        relatedVenue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenuePartner = /* GraphQL */ `
  query GetVenuePartner($id: ID!) {
    getVenuePartner(id: $id) {
      id
      venueId
      partnerId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      partner {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenuePartners = /* GraphQL */ `
  query ListVenuePartners(
    $filter: ModelVenuePartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenuePartners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        partnerId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        partner {
          id
          name
          airtableId
          isActive
          icon
          venues {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueCategory = /* GraphQL */ `
  query GetVenueCategory($id: ID!) {
    getVenueCategory(id: $id) {
      id
      venueId
      categoryId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueCategorys = /* GraphQL */ `
  query ListVenueCategorys(
    $filter: ModelVenueCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        categoryId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        category {
          id
          name
          airtableId
          description
          sortOrder
          status
          isActive
          isHighlighted
          venues {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueAtmosphere = /* GraphQL */ `
  query GetVenueAtmosphere($id: ID!) {
    getVenueAtmosphere(id: $id) {
      id
      venueId
      atmosphereId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      atmosphere {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueAtmospheres = /* GraphQL */ `
  query ListVenueAtmospheres(
    $filter: ModelVenueAtmosphereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueAtmospheres(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueId
        atmosphereId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        atmosphere {
          id
          name
          airtableId
          isActive
          venues {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueCuisine = /* GraphQL */ `
  query GetVenueCuisine($id: ID!) {
    getVenueCuisine(id: $id) {
      id
      venueId
      cuisineId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      cuisine {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueCuisines = /* GraphQL */ `
  query ListVenueCuisines(
    $filter: ModelVenueCuisineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueCuisines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        cuisineId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        cuisine {
          id
          name
          airtableId
          images
          isActive
          venues {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueAward = /* GraphQL */ `
  query GetVenueAward($id: ID!) {
    getVenueAward(id: $id) {
      id
      venueId
      awardId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      award {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueAwards = /* GraphQL */ `
  query ListVenueAwards(
    $filter: ModelVenueAwardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueAwards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        awardId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        award {
          id
          name
          airtableId
          primaryText
          secondaryText
          icon
          status
          isActive
          venues {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueTag = /* GraphQL */ `
  query GetVenueTag($id: ID!) {
    getVenueTag(id: $id) {
      id
      venueId
      tagId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueTags = /* GraphQL */ `
  query ListVenueTags(
    $filter: ModelVenueTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        venueId
        tagId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        tag {
          id
          name
          airtableId
          isActive
          venues {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVenueSeatingOption = /* GraphQL */ `
  query GetVenueSeatingOption($id: ID!) {
    getVenueSeatingOption(id: $id) {
      id
      venueId
      seatingOptionId
      venue {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      seatingOption {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVenueSeatingOptions = /* GraphQL */ `
  query ListVenueSeatingOptions(
    $filter: ModelVenueSeatingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenueSeatingOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        venueId
        seatingOptionId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        seatingOption {
          id
          name
          airtableId
          isActive
          venues {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const accoladesByAirtableId = /* GraphQL */ `
  query AccoladesByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAccoladeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accoladesByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        icon
        sortOrder
        awards {
          items {
            id
            accoladeId
            awardId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            accoladeId
            tagId
            accolade {
              id
              name
              airtableId
              icon
              sortOrder
              isActive
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const allergysByAirtableId = /* GraphQL */ `
  query AllergysByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAllergyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    allergysByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const atmospheresByAirtableId = /* GraphQL */ `
  query AtmospheresByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAtmosphereFilterInput
    $limit: Int
    $nextToken: String
  ) {
    atmospheresByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const awardsByAirtableId = /* GraphQL */ `
  query AwardsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAwardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    awardsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        primaryText
        secondaryText
        icon
        status
        isActive
        venues {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const categorysByAirtableId = /* GraphQL */ `
  query CategorysByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categorysByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const categorysBySortOrder = /* GraphQL */ `
  query CategorysBySortOrder(
    $id: ID
    $sortOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categorysBySortOrder(
      id: $id
      sortOrder: $sortOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        description
        sortOrder
        status
        isActive
        isHighlighted
        venues {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const cuisinesByAirtableId = /* GraphQL */ `
  query CuisinesByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCuisineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    cuisinesByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        images
        isActive
        venues {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dietsByAirtableId = /* GraphQL */ `
  query DietsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelDietFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dietsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const marketsByAirtableId = /* GraphQL */ `
  query MarketsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMarketFilterInput
    $limit: Int
    $nextToken: String
  ) {
    marketsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        image
        isActive
        venues {
          items {
            id
            name
            airtableId
            sevenRoomsVenueId
            sevenRoomsMunicipalityId
            description
            websiteUrl
            menuUrl
            startOfDay
            dineDash
            bookingPolicy
            paymentPolicy
            phoneNumber
            city
            state
            zipcode
            address
            googlePlaceId
            neighborhoodId
            neighborhood {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            normalizedName
            marketId
            market {
              id
              name
              airtableId
              image
              isActive
              createdAt
              updatedAt
            }
            geocodeId
            geocode {
              id
              latitude
              longitude
              expirationTimestamp
              createdAt
              updatedAt
            }
            categories {
              nextToken
            }
            atmospheres {
              nextToken
            }
            cuisines {
              nextToken
            }
            cuisineNames
            awards {
              nextToken
            }
            tags {
              nextToken
            }
            seatingOptions {
              nextToken
            }
            priceRange
            imagesSmall
            images
            imagesFull
            timezoneName
            sortOrder
            isActive
            specialEventDate
            eventSalesClosed
            eventSoldOut
            badgeText
            isTest
            isSignatureCollection
            isInsider
            salesStartDate
            salesEndDate
            eventDates
            isSoldOut
            isCardholderExclusive
            venueType
            experienceId
            isHidden
            relatedVenues {
              nextToken
            }
            partners {
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        neighborhoods {
          items {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const neighborhoodsByAirtableId = /* GraphQL */ `
  query NeighborhoodsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelNeighborhoodFilterInput
    $limit: Int
    $nextToken: String
  ) {
    neighborhoodsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        marketId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const partnerByAirtableId = /* GraphQL */ `
  query PartnerByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPartnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    partnerByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        icon
        venues {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const premierLocationsBySortOrder = /* GraphQL */ `
  query PremierLocationsBySortOrder(
    $id: ID
    $sortOrder: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPremierLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    premierLocationsBySortOrder(
      id: $id
      sortOrder: $sortOrder
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const premierLocationsByAirtableId = /* GraphQL */ `
  query PremierLocationsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelPremierLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    premierLocationsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        subtitle
        airtableId
        sortOrder
        chefName
        images
        description
        status
        spotlight
        isActive
        venues {
          items {
            id
            venueId
            premierLocationId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            premierLocation {
              id
              name
              subtitle
              airtableId
              sortOrder
              chefName
              images
              description
              status
              spotlight
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationsByUserId = /* GraphQL */ `
  query ReservationsByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReservationsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationId
        slotOptionId
        slotName
        bookingPolicy
        paymentPolicy
        status
        sevenRoomsStatusCode
        date
        time
        guests
        paymentAccountReferenceId
        paymentGratuity
        paymentServiceCharge
        paymentTax
        paymentSubTotal
        paymentRule
        isActive
        isExclusive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationBySevenRoomsReservationId = /* GraphQL */ `
  query ReservationBySevenRoomsReservationId(
    $sevenRoomsReservationId: String
    $sortDirection: ModelSortDirection
    $filter: ModelReservationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReservationBySevenRoomsReservationId(
      sevenRoomsReservationId: $sevenRoomsReservationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationId
        slotOptionId
        slotName
        bookingPolicy
        paymentPolicy
        status
        sevenRoomsStatusCode
        date
        time
        guests
        paymentAccountReferenceId
        paymentGratuity
        paymentServiceCharge
        paymentTax
        paymentSubTotal
        paymentRule
        isActive
        isExclusive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationRequestsByUserId = /* GraphQL */ `
  query ReservationRequestsByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReservationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReservationRequestsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        guests
        realDateTime
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationRequestId
        isActive
        referenceCode
        isCustomerCancel
        c1Status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationRequestsBySevenRoomsReservationRequestId = /* GraphQL */ `
  query ReservationRequestsBySevenRoomsReservationRequestId(
    $sevenRoomsReservationRequestId: String
    $sortDirection: ModelSortDirection
    $filter: ModelReservationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReservationRequestsBySevenRoomsReservationRequestId(
      sevenRoomsReservationRequestId: $sevenRoomsReservationRequestId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        guests
        realDateTime
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationRequestId
        isActive
        referenceCode
        isCustomerCancel
        c1Status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reservationRequestsByReferenceCode = /* GraphQL */ `
  query ReservationRequestsByReferenceCode(
    $referenceCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelReservationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ReservationRequestsByReferenceCode(
      referenceCode: $referenceCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        time
        guests
        realDateTime
        userId
        user {
          id
          c1CustRefId
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          userBirthday
          partnerBirthday
          userAnniversary
          notes
          defaultTipPercent
          allergies {
            items {
              id
              userId
              allergyId
              createdAt
              updatedAt
            }
            nextToken
          }
          diets {
            items {
              id
              userId
              dietId
              createdAt
              updatedAt
            }
            nextToken
          }
          hidePhoneNumberAlert
          isTest
          hidePaymentMethodAlert
          defaultPaymentMethodAccountId
          hideExclusiveTaggingAlert
          createdAt
          updatedAt
        }
        venueId
        venue {
          id
          name
          airtableId
          sevenRoomsVenueId
          sevenRoomsMunicipalityId
          description
          websiteUrl
          menuUrl
          startOfDay
          dineDash
          bookingPolicy
          paymentPolicy
          phoneNumber
          city
          state
          zipcode
          address
          googlePlaceId
          neighborhoodId
          neighborhood {
            id
            name
            airtableId
            isActive
            marketId
            createdAt
            updatedAt
          }
          normalizedName
          marketId
          market {
            id
            name
            airtableId
            image
            isActive
            venues {
              nextToken
            }
            neighborhoods {
              nextToken
            }
            createdAt
            updatedAt
          }
          geocodeId
          geocode {
            id
            latitude
            longitude
            expirationTimestamp
            createdAt
            updatedAt
          }
          categories {
            items {
              id
              venueId
              categoryId
              createdAt
              updatedAt
            }
            nextToken
          }
          atmospheres {
            items {
              id
              venueId
              atmosphereId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisines {
            items {
              id
              venueId
              cuisineId
              createdAt
              updatedAt
            }
            nextToken
          }
          cuisineNames
          awards {
            items {
              id
              venueId
              awardId
              createdAt
              updatedAt
            }
            nextToken
          }
          tags {
            items {
              id
              venueId
              tagId
              createdAt
              updatedAt
            }
            nextToken
          }
          seatingOptions {
            items {
              id
              venueId
              seatingOptionId
              createdAt
              updatedAt
            }
            nextToken
          }
          priceRange
          imagesSmall
          images
          imagesFull
          timezoneName
          sortOrder
          isActive
          specialEventDate
          eventSalesClosed
          eventSoldOut
          badgeText
          isTest
          isSignatureCollection
          isInsider
          salesStartDate
          salesEndDate
          eventDates
          isSoldOut
          isCardholderExclusive
          venueType
          experienceId
          isHidden
          relatedVenues {
            items {
              id
              venueId
              relatedVenueId
              createdAt
              updatedAt
            }
            nextToken
          }
          partners {
            items {
              id
              venueId
              partnerId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        sevenRoomsReservationRequestId
        isActive
        referenceCode
        isCustomerCancel
        c1Status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const seatingOptionsByAirtableId = /* GraphQL */ `
  query SeatingOptionsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSeatingOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    seatingOptionsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const specialOccasionByAirtableId = /* GraphQL */ `
  query SpecialOccasionByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelSpecialOccasionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    specialOccasionByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tagsByAirtableId = /* GraphQL */ `
  query TagsByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tagsByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        isActive
        venues {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByC1CustRefId = /* GraphQL */ `
  query UserByC1CustRefId(
    $c1CustRefId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByC1CustRefId(
      c1CustRefId: $c1CustRefId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        c1CustRefId
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        userBirthday
        partnerBirthday
        userAnniversary
        notes
        defaultTipPercent
        allergies {
          items {
            id
            userId
            allergyId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            allergy {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        diets {
          items {
            id
            userId
            dietId
            user {
              id
              c1CustRefId
              marketId
              userBirthday
              partnerBirthday
              userAnniversary
              notes
              defaultTipPercent
              hidePhoneNumberAlert
              isTest
              hidePaymentMethodAlert
              defaultPaymentMethodAccountId
              hideExclusiveTaggingAlert
              createdAt
              updatedAt
            }
            diet {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        hidePhoneNumberAlert
        isTest
        hidePaymentMethodAlert
        defaultPaymentMethodAccountId
        hideExclusiveTaggingAlert
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const venuesByMarketId = /* GraphQL */ `
  query VenuesByMarketId(
    $marketId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    venuesByMarketId(
      marketId: $marketId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const venuesByAirtableId = /* GraphQL */ `
  query VenuesByAirtableId(
    $airtableId: String
    $sortDirection: ModelSortDirection
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    venuesByAirtableId(
      airtableId: $airtableId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchVenues = /* GraphQL */ `
  query SearchVenues(
    $filter: SearchableVenueFilterInput
    $sort: SearchableVenueSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchVenues(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        airtableId
        sevenRoomsVenueId
        sevenRoomsMunicipalityId
        description
        websiteUrl
        menuUrl
        startOfDay
        dineDash
        bookingPolicy
        paymentPolicy
        phoneNumber
        city
        state
        zipcode
        address
        googlePlaceId
        neighborhoodId
        neighborhood {
          id
          name
          airtableId
          isActive
          marketId
          createdAt
          updatedAt
        }
        normalizedName
        marketId
        market {
          id
          name
          airtableId
          image
          isActive
          venues {
            items {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            nextToken
          }
          neighborhoods {
            items {
              id
              name
              airtableId
              isActive
              marketId
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        geocodeId
        geocode {
          id
          latitude
          longitude
          expirationTimestamp
          createdAt
          updatedAt
        }
        categories {
          items {
            id
            venueId
            categoryId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            category {
              id
              name
              airtableId
              description
              sortOrder
              status
              isActive
              isHighlighted
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        atmospheres {
          items {
            id
            venueId
            atmosphereId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            atmosphere {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisines {
          items {
            id
            venueId
            cuisineId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            cuisine {
              id
              name
              airtableId
              images
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        cuisineNames
        awards {
          items {
            id
            venueId
            awardId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            award {
              id
              name
              airtableId
              primaryText
              secondaryText
              icon
              status
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        tags {
          items {
            id
            venueId
            tagId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            tag {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        seatingOptions {
          items {
            id
            venueId
            seatingOptionId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            seatingOption {
              id
              name
              airtableId
              isActive
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        priceRange
        imagesSmall
        images
        imagesFull
        timezoneName
        sortOrder
        isActive
        specialEventDate
        eventSalesClosed
        eventSoldOut
        badgeText
        isTest
        isSignatureCollection
        isInsider
        salesStartDate
        salesEndDate
        eventDates
        isSoldOut
        isCardholderExclusive
        venueType
        experienceId
        isHidden
        relatedVenues {
          items {
            id
            venueId
            relatedVenueId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            relatedVenue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        partners {
          items {
            id
            venueId
            partnerId
            venue {
              id
              name
              airtableId
              sevenRoomsVenueId
              sevenRoomsMunicipalityId
              description
              websiteUrl
              menuUrl
              startOfDay
              dineDash
              bookingPolicy
              paymentPolicy
              phoneNumber
              city
              state
              zipcode
              address
              googlePlaceId
              neighborhoodId
              normalizedName
              marketId
              geocodeId
              cuisineNames
              priceRange
              imagesSmall
              images
              imagesFull
              timezoneName
              sortOrder
              isActive
              specialEventDate
              eventSalesClosed
              eventSoldOut
              badgeText
              isTest
              isSignatureCollection
              isInsider
              salesStartDate
              salesEndDate
              eventDates
              isSoldOut
              isCardholderExclusive
              venueType
              experienceId
              isHidden
              createdAt
              updatedAt
            }
            partner {
              id
              name
              airtableId
              isActive
              icon
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
