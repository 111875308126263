import moment from 'moment';

const START_TIME = '06:00';
const END_TIME = '05:45';
const DATE_RANGE_INCREMENT = 5;

export const generateMultipleAvailabilityRequestPayload = ({ sevenRoomsVenueId, partySize, startDate, endDate, experienceId }) => {
    const DATE_FORMAT = 'YYYY-MM-DD';
    const start = moment(startDate, DATE_FORMAT);
    const end = moment(endDate, DATE_FORMAT);
    const numberOfDaysApart = end.diff(start, 'days');
    const allDates = [];
    for (let day = 0; day < numberOfDaysApart - 1; day++) {
      const date = moment(startDate, DATE_FORMAT);
      date.add(day, 'days');
      const availabilityObj = { sevenRoomsVenueId, date: date.format(DATE_FORMAT), startTime: START_TIME, endTime: END_TIME, partySize, experienceId };
      allDates.push(availabilityObj);
    }
    return allDates;
}

/**
 * Generates an array of payloads for date range availability requests with each call spanning DATE_RANGE_INCREMENT number of days till the total days out.
 * Used to parallelize the availability calls because of the response payload size limit of 1MB
 *
 * @param {Object} params - The parameters for the request.
 * @param {string} params.sevenRoomsVenueId - The ID of the venue.
 * @param {number} params.partySize - The size of the party.
 * @param {string} params.date - The start date for the availability check.
 * @param {number} params.daysOut - The number of days out to check for availability.
 * @returns {Array<Object>} - An array of availability request objects.
 */
export const generateMultipleDateRangeAvailabilityRequestPayload = ({ sevenRoomsVenueId, partySize, date, daysOut }) => {
  const DATE_FORMAT = 'YYYY-MM-DD';
  const allDates = [];
  const queryStartDate = moment(date, DATE_FORMAT);
  for (let day = 0; day < daysOut; day+=DATE_RANGE_INCREMENT) {
    const currentQueryDate = queryStartDate.clone().add(day, 'days');
    const remainingDays = daysOut - day;
    const increment = remainingDays < DATE_RANGE_INCREMENT ? remainingDays : DATE_RANGE_INCREMENT;
    const availabilityObj = { sevenRoomsVenueId, date: currentQueryDate.format(DATE_FORMAT), startTime: START_TIME, endTime: END_TIME, partySize, daysOut: increment };
    allDates.push(availabilityObj);
  }
  return allDates;
}

export const generateEventAvailabilityRequestPayload = ({ sevenRoomsVenueId, eventDates = [], partySize, experienceId }) => {
    const allEventDates = [];
    for (let eventDate of eventDates) {
        if (isNotExpired(eventDate)) {
            const availabilityObj = { sevenRoomsVenueId, date: eventDate, startTime: START_TIME, endTime: END_TIME, partySize, experienceId };
            allEventDates.push(availabilityObj);
        }
    }
    return allEventDates;
}

export const generateListSearchRequestPayload = ({ currentDate, currentTime, halo, venues = [], date, time, partySize, sevenRoomsVenueIds = [], page }) => {
    return venues.map((venue, index) => ({
        currentDate,
        currentTime,
        venues: [venues[index]],
        halo,
        date,
        time,
        partySize,
        sevenRoomsVenueIds,
        page
     }));
}


// We have an isExpired function but it's written with Luxon
const isNotExpired = (date) => {
    const today = moment();
    today.set('hour', 0);
    today.set('minute', 0);
    today.set('second', 0);
    today.set('millisecond', 0);
    const eventDate = moment(date);
    return today.isSameOrBefore(eventDate);
}
