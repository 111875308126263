import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { useAuth } from '../../utils/useAuth';
import { useOnboardingRedirect } from '../../utils/useOnboardingRedirect';
import CommonLoading from '../common/CommonLoading';
import { setPermalinkRouteRedirect } from '../../utils/sessionStorageHelper';
import { getPermalinkRouteRedirectLocation } from '../onboarding/onboardingHelpers';

const PermaLink = () => {
  const { permaLinks } = useSelector((state) => state.config);
  const { slug } = useParams();
  const history = useHistory();
  const location = useLocation();
  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();
  const shouldOnboard = useOnboardingRedirect();

  useEffect(() => {
    if (!permaLinks) {
      return;
    } 
    const pathname = permaLinks?.hasOwnProperty(slug) ? permaLinks[slug] : '/';
    setPermalinkRouteRedirect(pathname);
    signinWithJwt(assertion);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permaLinks]);

  useEffect(() => {

    if (!user.session.isAuthenticated || shouldOnboard) {
      return;
    };

    history.replace({
      pathname: getPermalinkRouteRedirectLocation(),
      search: location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session.isAuthenticated, shouldOnboard]);

  return (
    !user.session.isAuthenticated && (
      <CommonLoading active={!user.session.isAuthenticated} embedded={true} />
    )
  );
};

export default PermaLink;
