import { useSelector } from 'react-redux';

export const useFeatureFlags = () => {
  const { features, hasRequestSucceeded: hasLoaded } = useSelector(
    (state) => state.featureToggle
  );

  return {
    features,
    refreshAvailabilityCalendarFlagIsEnabled: hasLoaded
      ? features.get('dining-cx-refresh-availability-calendar')
      : false,
    refreshLandingPageFlagIsEnabled: hasLoaded
      ? features.get('dining-cx-refresh-landing-page')
      : false,
  };
};
