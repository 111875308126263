import user from '../User/UserReducer';
import appData from '../AppData/AppDataReducer';
import markets from '../Markets/MarketsReducer';
import atmospheres from '../Atmospheres/AtmospheresReducer';
import seatingOptions from '../SeatingOptions/SeatingOptionsReducer';
import neighborhoods from '../Neighborhoods/NeighborhoodsReducer';
import animations from '../Animations/AnimationsReducer';
import categories from '../Categories/CategoriesReducer';
import awards from '../Awards/AwardsReducer';
import tags from '../Tags/TagsReducer';
import diets from '../Diets/DietsReducer';
import allergies from '../Allergies/AllergiesReducer';
import premierLocation from '../PremierLocation/PremierLocationsReducer';
import accolades from '../Accolades/AccoladesReducer';
import venues from '../Venues/VenuesReducer';
import cuisines from '../Cuisines/CuisinesReducer';
import filters from '../Filter/FilterReducer';
import search from '../Search/SearchReducer';
import availability from '../Availability/AvailabilityReducer';
import reservations from '../Reservations/ReservationsReducer';
import error from '../Error/ErrorReducer';
import config from '../Config/ConfigReducer';
import featureToggle from '../FeatureToggle/FeatureToggleReducer';

const reducers = {
  appData,
  markets,
  atmospheres,
  seatingOptions,
  neighborhoods,
  animations,
  categories,
  accolades,
  awards,
  tags,
  diets,
  allergies,
  premierLocation,
  venues,
  cuisines,
  filters,
  search,
  availability,
  reservations,
  user,
  error,
  config,
  featureToggle,
};

export default reducers;
