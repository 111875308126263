import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TableParameter from './TableParameter';
import FixedBottomButtonWrapper from '../../components/core/FAB/FixedBottomButtonWrapper';
import TimeParameter from '../../components/core/FAB/TimeParameter';
import VenueImageHeader from '../../components/venue/VenueImageHeader';
import { actionAwardListRequest } from '../../store/Awards/AwardsAction';
import { actionTagListRequest } from '../../store/Tags/TagsAction';
import { actionGetVenueRequest } from '../../store/Venues/VenuesAction';
import moment from 'moment';
import {
  actionSetFabAvailabilityDate,
  actionSetFabAvailabilityTime,
  actionSetFabAvailabilityGuests,
  actionClearAvailability,
  actionLoadDateAvailabilityRequest,
  actionClearListAvailabilities,
  actionGetEventAvailabilityRequest,
  actionGetMultipleAvailabilitiesRequest,
  actionGetDateRangeAvailabilityRequest, actionClearDateRangeAvailabilities,
} from '../../store/Availability/AvailabilityAction';
import { useViewport } from '../../utils/useViewport';
import {
  generateAvailabilityInputForSpecialEvent, generateGetDateRangeAvailabilityInput,
  generateListAvailabilityInput,
} from '../../utils/generateAvailabilityInput';
import defaultReservation from './helpers/defaultReservation';
import CommonLoading from '../common/CommonLoading';
import { DATE_FORMAT_YEAR_FIRST } from '../../assets/dateFormats';
import ReservationSheet from '../../components/core/Sheet/ReservationSheet';
import DesktopVenueLayout from './DesktopVenueLayout';
import MobileVenueLayout from './MobileVenueLayout';
import useEventFlag from '../../utils/useEventFlag';
import DateTime from '../../utils/dateTimeHelpers';
import { getDaysInMonthFromDate, getRemainingDaysInMonth } from "../../utils/dayMonthHelper";
import { useFeatureFlags } from '../../utils/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));

const widthBreakpoint = 960;

const Venue = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { venue } = useSelector((state) => state.venues);
  const { user } = useSelector((state) => state.user);
  const scrollRef = useRef(null);
  const { defaultDate, defaultTime, defaultGuests } = defaultReservation();
  const [date] = useState(defaultDate);
  const [time] = useState(defaultTime);
  const [guests] = useState(defaultGuests);
  const [isOpen, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { refreshAvailabilityCalendarFlagIsEnabled: calendarFeatureFlag } =
    useFeatureFlags();

  const {
    selectedDate,
    selectedTime,
    selectedGuests,
    submittedTime,
    submittedDate,
    submittedGuests,
    submittedTickets,
    loadingAvailability,
    list,
  } = useSelector((state) => state.availability);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  useEffect(() => {
    dispatch(actionClearAvailability());
    dispatch(actionClearListAvailabilities());
    dispatch(actionClearDateRangeAvailabilities());

    return () => {
      dispatch(actionClearAvailability());
      dispatch(actionClearListAvailabilities());
      dispatch(actionClearDateRangeAvailabilities());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(actionGetVenueRequest(id));
    dispatch(actionAwardListRequest());
    dispatch(actionTagListRequest());
  }, [dispatch, id]);

  useEffect(() => {
    if (venue && id === venue.id) setMounted(true);
  }, [venue, id]);

  const eventStatus = useEventFlag(venue, submittedDate);
  const shouldShowFab = useMemo(() => {
    return !eventStatus.venueHasEvent && venue?.isActive && !calendarFeatureFlag
  }, [eventStatus.venueHasEvent, venue?.isActive, calendarFeatureFlag]);
  const currentDate = DateTime.now().startOf('day');

  useEffect(() => {
    //Note: It is important to only dispatch request when venue id is loaded
    if (venue !== null && id === venue.id && venue.isActive) {
      //this is temporary and will be taken out
      if (eventStatus.venueHasEvent && !eventStatus.isEventNoLongerAvailable) {
        const availableDate = venue.eventDates.find(
          (date) => new DateTime(date) >= currentDate
        );

        if (availableDate) {
          const input = generateAvailabilityInputForSpecialEvent(
            venue,
            availableDate,
            submittedTickets
          );

          dispatch(actionGetEventAvailabilityRequest(input));
        }
      } else {
        if (calendarFeatureFlag) {
          const currentDate = new Date();
          const input = generateGetDateRangeAvailabilityInput(
            venue,
            currentDate,
            submittedGuests,
            getRemainingDaysInMonth(currentDate) + getDaysInMonthFromDate(currentDate,1)
          );
          dispatch(actionGetDateRangeAvailabilityRequest(input));
        } else {
          const input = generateListAvailabilityInput(
            venue,
            submittedDate,
            submittedTime,
            submittedGuests
          );
          dispatch(actionGetMultipleAvailabilitiesRequest(input));
        }
      }
    }
    /**
     * Not adding selectedDate, selectedTime, selectedGuests to the deps array
     * since we don't want this effect running every time a date, time, and
     * guests input change.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, venue, location]);

  const handleCloseFab = () => {
    // unsubmitted values on FAB should still maintain the submitted values
    if (
      moment(selectedDate).format(DATE_FORMAT_YEAR_FIRST) !==
      moment(submittedDate).format(DATE_FORMAT_YEAR_FIRST)
    ) {
      dispatch(actionSetFabAvailabilityDate({ selectedDate: submittedDate }));
    }

    if (selectedTime !== submittedTime) {
      dispatch(actionSetFabAvailabilityTime({ selectedTime: submittedTime }));
    }

    if (selectedGuests !== submittedGuests) {
      dispatch(
        actionSetFabAvailabilityGuests({ selectedGuests: submittedGuests })
      );
    }
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleDone = () => {
    setOpen(false);
    if (eventStatus.venueHasEvent) {
      if (eventStatus.isEventNoLongerAvailable) {
        return;
      }
      // This will be seperated to make it easier to remove once the event is over
      const input = generateAvailabilityInputForSpecialEvent(
        venue,
        selectedDate,
        selectedGuests
      );
      dispatch(actionLoadDateAvailabilityRequest(input));
    } else {
      const input = generateListAvailabilityInput(
        venue,
        selectedDate,
        selectedTime,
        selectedGuests
      );
      dispatch(actionGetMultipleAvailabilitiesRequest(input));
    }
  };

  const hasRelatedEvents = venue?.relatedVenues?.items?.length > 0;
  const [eventList, setEventList] = useState(null);

  useEffect(() => {
    const eventsArray = [];
    if (hasRelatedEvents) {
      venue?.relatedVenues?.items?.forEach((venueEvent) => {
        if (!user.isTest && !venueEvent.relatedVenue.isTest) {
          eventsArray.push(venueEvent.relatedVenue);
        }
        if (user.isTest) {
          eventsArray.push(venueEvent.relatedVenue);
        }
      });
    }
    setEventList(eventsArray);
  }, [venue, hasRelatedEvents?.items, hasRelatedEvents, user.isTest]);

  const mainContent =
    venue && width > widthBreakpoint ? (
      <DesktopVenueLayout
        venue={venue}
        handleClick={handleClick}
        date={submittedDate}
        time={submittedTime}
        user={user}
        guests={eventStatus.venueHasEvent ? submittedTickets : submittedGuests}
        loadingAvailability={loadingAvailability}
        list={list}
        hasRelatedEvents={hasRelatedEvents}
        eventList={eventList}
      />
    ) : (
      <MobileVenueLayout
        venue={venue}
        handleClick={handleClick}
        date={submittedDate}
        time={submittedTime}
        user={user}
        guests={eventStatus.venueHasEvent ? submittedTickets : submittedGuests}
        loadingAvailability={loadingAvailability}
        list={list}
        navigationMenuVisible={navigationMenuVisible}
        hasRelatedEvents={hasRelatedEvents}
        eventList={eventList}
      />
    );

  return (
    <Box className={classes.root}>
      {mounted && !navigationMenuVisible ? (
        <>
          <Grid
            item
            className={classes.container}
            xs={12}
            container
            justify="center"
            alignContent="center"
            alignItems={width > widthBreakpoint ? 'flex-start' : 'stretch'}
            direction={width > widthBreakpoint ? 'row' : 'column'}
            ref={scrollRef}>
            <VenueImageHeader venue={venue} />
            {mainContent}
          </Grid>
          <Box>
            <ReservationSheet />
            {shouldShowFab && (
              <>
                <FixedBottomButtonWrapper onClick={handleClick}>
                  <TimeParameter date={date} time={time} guests={guests} />
                </FixedBottomButtonWrapper>
                <TableParameter
                  isOpen={isOpen}
                  setOpen={setOpen}
                  date={date}
                  time={time}
                  guests={guests}
                  handleDone={handleDone}
                  handleCloseFab={handleCloseFab}
                  defaultTime={defaultTime}
                  specialEventDate={
                    eventStatus.venueHasEvent ? venue?.eventDates : null
                  }
                />
              </>
            )}
          </Box>
        </>
      ) : (
        <CommonLoading active={!mounted} />
      )}
    </Box>
  );
};

export default Venue;
