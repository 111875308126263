/**
 * Calculate the remaining days in the month inclusive of the given date.
 * @param {Date} date - The date for which to calculate the remaining days.
 * @return {number} The number of remaining days in the month inclusive of the given date.
 */
export const getRemainingDaysInMonth = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
  const currentDay = date.getDate();
  return totalDaysInMonth - currentDay + 1;
};

/**
 * Get the number of days in the next month of the given date.
 * @param {Date} date - The date for which to determine the number of days in the next month.
 * @param {number} monthOffset - The number of months to offset from the given date.
 * @return {number} The number of days in the next month.
 */
export const getDaysInMonthFromDate = (date, monthOffset = 0) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  return new Date(year, month + 1 + monthOffset, 0).getDate();
};
