import React, {useEffect, useState} from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Typography from '../../components/overrides/Typography';
import { actionSetAnimationPushUp } from '../../store/Animations/AnimationsAction';
import {
  actionSetDraftReservation,
  actionSetOpenSheet,
} from '../../store/Reservations/ReservationsAction';
import { useViewport } from '../../utils/useViewport';
import { AVAILABLE_TIMES_LABEL } from '../../assets/copy';
import { DATE_FORMAT_YEAR_FIRST } from '../../assets/dateFormats';

import {
  convertUserAllergies,
  convertUserDiets,
} from '../../utils/reservationFilters';
import {
  checkIfHasTimeslots,
  getFormattedDate,
  getFormattedLongDateWithWeekday,
} from '../../utils/dateTime';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import { useComponentWillUnmount } from '../../utils/useComponentWillUnmount';
import useEventFlag from '../../utils/useEventFlag';
import clsx from 'clsx';
import EventAvailability from './EventAvailability';
import VenueAvailability from './VenueAvailability';
import { redirectToReservePage } from '../reserve/helpers/reserveHelpers';
import { useFeatureFlags } from '../../utils/useFeatureFlags';

const useStyles = makeStyles((theme) => ({
  root: {
    width:
      '-webkit-fill-available' /* Mozilla-based browsers will ignore this. */,
    padding: theme.spacing(3, 0, 0),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 0, 4),
    },
  },

  rootCalendar: {
    width:
      '-webkit-fill-available' /* Mozilla-based browsers will ignore this. */,
    padding: theme.spacing(2, 0, 0),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0, 4),
    },
  },

  mozRoot: {
    width: '-moz-available' /* WebKit-based browsers will ignore this. */,
  },

  availHeader: {
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 7),
    },
  },
  divider: {
    width: '100%',
    margin: theme.spacing(4, 0, 0),
    [theme.breakpoints.up('xl')]: {
      width: 'calc(100% - 56px)',
      margin: ({ type }) => {
        if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL)
          return theme.spacing(5, 7, 0);
      },
    },
  },
  availTime: {
    width: '100%',
    '& *::-webkit-scrollbar': {
      display: 'none',
    },
  },
  noAvailabilityInfoContainer: {
    padding: theme.spacing(2, 3, 0),
    justifyContent: 'normal',
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0, 0, 7),
      justifyContent: 'normal',
    },
  },
  noAvailabilityInfoContent: {
    width: '100%',
    maxWidth: '100%',
  },
  timeBasic: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 7),
  },
  skeleton: {
    [theme.breakpoints.up('xl')]: {
      margin: theme.spacing(2, 7),
    },
    margin: theme.spacing(1, 0),
  },
  line: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.gray[5],
  },
}));

const Availability = ({
  venue,
  user,
  path = '/reserve',
  action,
  type = TIME_AVAILABILITY_TYPES.HORIZONTAL,
  availableTextLabel = AVAILABLE_TIMES_LABEL,
  date,
  time,
  guests,
  list,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({ type: TIME_AVAILABILITY_TYPES.HORIZONTAL });
  const { width } = useViewport();
  const history = useHistory();
  const { loadingAvailability } = useSelector((state) => state.availability);

  const [showMore, setShowMore] = useState(false);
  const [hasAvailabilityForSelectedDay, setHasAvailabilityForSelectedDay] =
    useState(true);
  const [hasAvailabilityForNext7Days, setHasAvailabilityForNext7Days] =
    useState(false);
  //takes priority over other conditions for the displaying availabilities
  const { venueHasEvent: isEvent } = useEventFlag(venue);
  const loadedAvailability = !loadingAvailability && list;
  const selectedDateAvailabilities = isEvent ? list : list?.slice(0, 1);
  const next7DaysAvailability = list?.slice(1);
  const selectedDateString = getFormattedLongDateWithWeekday(date);

  const { refreshAvailabilityCalendarFlagIsEnabled: shouldDisplayCalendar } =
    useFeatureFlags();


  useComponentWillUnmount(() => {
    setShowMore(false);
  });

  useEffect(() => {
    if (loadedAvailability) {
      const availabilityForSelectedDay = checkIfHasTimeslots(
        selectedDateAvailabilities
      );
      const availabilityForNext7Days = checkIfHasTimeslots(
        next7DaysAvailability
      );

      if (!availabilityForSelectedDay && availabilityForNext7Days) {
        setShowMore(true);
      }
      setHasAvailabilityForSelectedDay(availabilityForSelectedDay);
      setHasAvailabilityForNext7Days(availabilityForNext7Days);
    }
  }, [loadedAvailability, selectedDateAvailabilities, next7DaysAvailability]);

  const selectTimeSlot = (selDate, time, timeslot) => {
    const draftReservation = {
      venue,
      userId: user.id,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: user.defaultTipAmount,
      allergies: convertUserAllergies(user.allergies),
      diets: convertUserDiets(user.diets),
      notes: user.notes || '',
      emailAddress: user.emailAddress,
      specialOccasions: [],
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      ...timeslot,
      time: time,
      date: getFormattedDate(selDate, DATE_FORMAT_YEAR_FIRST),
      guests: guests,
      bookingPolicy: timeslot.bookingPolicy || venue.bookingPolicy,
    };

    if (timeslot.slotName === '') {
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionSetAnimationPushUp(true));
      redirectToReservePage(history);
    } else {
      dispatch(actionSetDraftReservation(draftReservation));
      dispatch(actionSetOpenSheet());
    }
  };

  const venueProps = {
    venue,
    hasAvailabilityForSelectedDay,
    hasAvailabilityForNext7Days,
    selectedDateString,
    type,
    selectedDateAvailabilities,
    selectTimeSlot,
    action,
    next7DaysAvailability,
    date,
    time,
    guests,
    showMore,
    loadedAvailability,
  };

  return (
    <Box className={clsx({
      [classes.root]: !shouldDisplayCalendar,
      [classes.rootCalendar]: shouldDisplayCalendar,
      [classes.mozRoot]: true}
    )}>
      {availableTextLabel && !shouldDisplayCalendar && (
        <Typography
          className={classes.availHeader}
          variant={
            width > WIDTH_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
          }
          component="h2">
          {availableTextLabel}
        </Typography>
      )}
      {isEvent ? (
        <EventAvailability {...venueProps} />
      ) : (
        <VenueAvailability {...venueProps} />
      )}
    </Box>
  );
};

export default Availability;
