import { DateTime as DateTimeHelpers } from 'luxon';

class DateTime {
  /**
   * Create a DateTime instance.
   * @param {string} dateTimeString - The date time string to be used to construct a DateTime object.
   * Can be an ISO string or other type following up with format prop in second param.
   * @param {Object} allOptions - all options to be used to construct the DateTime object.
   * @param {string} allOptions.format - format in which the dateTimeString are parsed. If dateTimeString is not an ISO
   * string, then `format` is required. For complete list of token:
   * https://moment.github.io/luxon/#/parsing?id=table-of-tokens
   * @param {string} allOptions.zone - use this zone if no offset is specified in the input string itself.
   * Will also convert the DateTime to this zone (default 'local')
   * @param {boolean} allOptions.setZone - override the zone with a zone specified in the string itself,
   * if it specifies one (default false)
   * @param {string} allOptions.locale - a locale string to use when parsing. Will also set the DateTime to this locale (default 'en-US')
   * @param {string} allOptions.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} allOptions.outputCalendar - the output calendar to set on the resulting DateTime instance
   */
  constructor(dateTimeString, allOptions = {}) {
    const { format, ...options } = allOptions;
    let dateTimeObj;
    if (dateTimeString && format && typeof format === 'string') {
      dateTimeObj = DateTimeHelpers.fromFormat(dateTimeString, format, options);
    } else if (dateTimeString && !format) {
      dateTimeObj = DateTimeHelpers.fromISO(dateTimeString, options);
    } else {
      dateTimeObj = DateTimeHelpers.now();
    }
    this._dt = dateTimeObj;
  }
  /**
   * Check the DateTime object is valid
   * @returns {boolean}
   * */
  get isValid() {
    return this._dt.isValid;
  }

  /**
   * Returns the year of DateTime object
   * @returns {int}
   * Ex: const date = new DateTime("May 25 2023", "LLLL dd yyyy");
   * date.year //=> 2023;
   * */
  get year() {
    return this._dt.year;
  }

  /**
   * Returns the month of DateTime object (1-12)
   * @returns {int}
   * */
  get month() {
    return this._dt.month;
  }

  /**
   * Returns the day of DateTime object (1-30ish)
   * @returns {int}
   * */
  get day() {
    return this._dt.day;
  }

  /**
   * Returns hours of the day of DateTime object (0-23)
   * @returns {int}
   * */
  get hour() {
    return this._dt.hour;
  }

  /**
   * Returns the minute of the hour of DateTime object (0-59)
   * @returns {int}
   * */
  get minute() {
    return this._dt.minute;
  }

  /**
   * Returns the second of the minute of DateTime object (0-59)
   * @returns {int}
   * */
  get second() {
    return this._dt.second;
  }

  /**
   * Returns name of the time zone
   * @return {string}
   * */
  get zoneName() {
    return this._dt.zoneName;
  }

  /**
   * Returns the ISO formatted date (Ex: 2023-05-25)
   * @returns {string}
   */
  get date() {
    return this._dt.toISODate();
  }

  /**
   * function "sets" the DateTime's zone to specified zone. Returns a newly-constructed DateTime.
   * @param {string} zoneName - Zone to be set as. (default 'local')
   * @param {Object=} options - options when setting new timezone.
   * @param {boolean} options.keepLocalTime - if true adjust the underlying time so that the local time stays the same,
   * but in the target zone. (default false)
   * @returns {DateTime} Newly-constructed DateTime.
   * */
  setZone(zoneName, options) {
    const newDateTime = this._dt.setZone(zoneName, options);
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * function "sets" the values of specified units. Returns a newly-constructed DateTime
   * @param {Object} units - Units of time and date to be set.
   * @returns {DateTime} Newly-constructed DateTime.
   * Ex:
   * const date = new DateTime();
   * date.set({ year: 2017 })
   * date.set({ hour: 8, minute: 30 })
   * date.set({ weekday: 5 })
   * */
  set(units) {
    const newDateTime = this._dt.set(units);
    const zoneName = newDateTime.zoneName;
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * function adds a period of time to this DateTime. Returns a newly-constructed DateTime
   * @param {Object} duration - Units of time and date to be added.
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = new DateTime();
   * date.plus({ days: 1 })
   * date.plus({ days: -1 })
   * date.plus({ years: 3 })
   * */
  plus(duration) {
    const newDateTime = this._dt.plus(duration);
    const zoneName = newDateTime.zoneName;
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * function subtracts a period of time to this DateTime. Returns a newly-constructed DateTime.
   * @param {Object} duration - Units of time and date to be subtracted.
   * @returns {DateTime} the resulting DateTime
   * Ex:
   * const date = new DateTime();
   * date.minus({ days: 1 })
   * date.minus({ days: -1 })
   * date.minus({ years: 3 })
   * */
  minus(duration) {
    const newDateTime = this._dt.minus(duration);
    const zoneName = newDateTime.zoneName;
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * function "sets" this DateTime to the beginning of a unit of time. Returns a newly-constructed DateTime.
   * @param {string} unit - The unit to go to the beginning of.
   * Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = new DateTime("2014-2-21");
   * date.startOf('month').toISODate(); //=> '2014-03-01'
   * date.startOf('year').toISODate(); //=> '2014-01-01'
   * date.startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * date.startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * date.startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   * */
  startOf(unit) {
    const newDateTime = this._dt.startOf(unit);
    const zoneName = newDateTime.zoneName;
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * function "sets" this DateTime to the end of a unit of time. Returns a newly-constructed DateTime.
   * @param {string} unit - The unit to go to the end of.
   * Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = new DateTime("May 25 2023", "LLLL dd yyyy");
   * date.endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * date.endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * date.endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * date.endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * date.endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   * */
  endOf(unit) {
    const newDateTime = this._dt.endOf(unit);
    const zoneName = newDateTime.zoneName;
    const isoDateString = newDateTime.toISO();
    return new DateTime(isoDateString, { zone: zoneName });
  }

  /**
   * Returns a string representation of this DateTime formatted according to the specified format string
   * @param {string} format - the format string
   * @param {Object=} options - options to override the configuration options on this DateTime
   * @returns {string} the resulting date time string.
   * Ex:
   * DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   * */
  toFormat(format, options) {
    return this._dt.toFormat(format, options);
  }

  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   * @param {Object=} options - options to override the configuration options on this DateTime
   * @param {string} options.format - 'basic' and 'extended' format. (default 'extended').
   * @param {boolean} options.suppressSeconds - exclude seconds from the format if they're 0. (default false).
   * @param {boolean} options.suppressMilliseconds - exclude milliseconds from the format if they're 0. (default false).
   * @param {boolean} options.includeOffset - include the offset, such as 'Z' or '-04:00'. (default true).
   * @param {boolean} options.extendedZone - add the time zone format extension. (default false).
   * @returns {string} the resulting date time string.
   * Ex:
   * DateTime.utc(1983, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   * */
  toISO(options) {
    return this._dt.toISO(options);
  }

  /**
   * Returns the epoch milliseconds of this DateTime.
   * @returns {number} the resulting timestamp in milliseconds..
   * */
  valueOf() {
    return this._dt.valueOf();
  }

  /**
   * Return whether this DateTime is in the same unit of time as another DateTime.
   * Higher-order units must also be identical for this function to return true.
   * Note that time zones are ignored in this comparison, which compares the local calendar time.
   * @param {DateTime} otherDateTime - Other DateTime object that is being to which compared.
   * @param {string} unit - the unit of time to check sameness on
   * @returns {boolean}
   * Ex:
   * const a = DateTime.now();
   * const b = a.plus({ day: 2 });
   * a.hasSame(b , 'day'); //~> false
   * */
  hasSame(otherDateTime, unit) {
    return this._dt.hasSame(otherDateTime._dt, unit);
  }

  /**
   * Equality check Two DateTimes are equal if and only if they represent the same millisecond, have the same zone
   * and location, and are both valid. To compare just the millisecond values, use +dt1 === +dt2.
   * @param {DateTime} otherDateTime - Other DateTime object that is being to which compared.
   * @returns {boolean}
   * Ex:
   * const a = DateTime.now();
   * const b = a;
   * a.equals(b); //~> true
   * */
  equals(otherDateTime) {
    return this._dt.equals(otherDateTime._dt);
  }

  /**
   * Create a DateTime from an input string and format string. Defaults to en-US if no locale has been specified,
   * regardless of the system's locale.
   * @param {string} text - Date time string.
   * @param {string} format - The format of the above Date Time string.
   * @param {Object=} options - Optional settings for constructing DateTime object.
   * For full list of options: https://moment.github.io/luxon/api-docs/index.html#datetimefromformat
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = DateTime.fromFormat("May 25 2023", "LLLL dd yyyy");
   * */
  static fromFormat(text, format, options) {
    if (typeof format !== 'string')
      throw new Error('expected format to be a string');
    return new DateTime(text, { format, ...options });
  }

  /**
   * Create a DateTime from an ISO 8601 string
   * regardless of the system's locale.
   * @param {string} text - Date time string.
   * @param {Object=} options - Optional settings for constructing DateTime object.
   * For full list of options: https://moment.github.io/luxon/api-docs/index.html#durationfromiso
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = DateTime.fromISO("2016-05-25");
   * */
  static fromISO(text, options) {
    if ((typeof options !== 'object' && options) || typeof text !== 'string')
      throw new Error('expected options to be an object');
    return new DateTime(text, options);
  }

  /**
   * Create a DateTime from a JavaScript Date object.
   * @param {Date} date - Date object.
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = DateTime.fromJSDate(new Date());
   * */
  static fromJSDate(date) {
    return DateTimeHelpers.fromJSDate(date);
  }

  /**
   * Create a DateTime for the current instant, in the system's time zone.
   * @returns {DateTime} the resulting DateTime.
   * Ex:
   * const date = DateTime.now();
   * */
  static now() {
    return new DateTime();
  }
}

export default DateTime;
